import { Tooltip } from "@mui/material";


//columns heading for department
export const departmentColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Department Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for role
export const roleColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "role_name", headerName: "Role Name", width: 230 },
  { field: "role_key", headerName: "Role Key", width: 230 },
  { field: "role_description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];


//columns heading for user type
export const userTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "type_name", headerName: "User Type Name", width: 230 },
  { field: "type_key", headerName: "User Type Key", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];


//columns heading for Menu List
export const menuListColumn = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "title", headerName: "Title", width: 230 },
  { field: "url_slug", headerName: "Slug", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "roles",
    headerName: "Permission",
    width: 300,
    filterable: false,
    renderCell: (params) => {
      let list = params.row.roles && params.row.roles.length > 0 ? params.row.roles : []
      let resArr = [];
      list.length > 0 && list.filter(function (item) {
        let i = resArr.findIndex(x => (x.role_id === item.role_id && x.menu_id === item && x.status === item.status));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return (
        <div className={`cellWithStatus ellips-css-data`}>
          {resArr.length > 0 && resArr.map((e, index) => `${index ? ', ' : ''}${(e.role_name).trim()}`)}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

//columns heading for third party
export const ThirdPartyColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];


export const QuestionTypeColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "question_type", headerName: "Question Type", width: 240 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];


export const BatchColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "display_name", headerName: "Batch Name", width: 400, renderCell: (params) => {
      return (
        <Tooltip title={params.row.display_name}>
          <div className="cellWithStatus">
            {params.row.display_name}
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "start_date", headerName: "Start Date & Time", width: 250, type: "date",
    renderCell: (params) => {
      return (
        <div>{params.row.start_date ? `${(params.row.start_date).split('T')[0]}${params.row.start_time ? ` ${params.row.start_time}` : ""} ` : "-"}</div>
      )
    }
  },
  {
    field: "end_date", headerName: "Expiry Date & Time", width: 250, type: "date",
    renderCell: (params) => {
      return (
        <div>{params.row.end_date ? `${(params.row.end_date).split('T')[0]}${params.row.end_time ? ` ${params.row.end_time}` : ""} ` : "-"}</div>
      )
    }
  },
];



export const TechnologyColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Technology Name", width: 230 },
  { field: "tech_type", headerName: "Technology Type", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const questionLevelColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "level_name", headerName: "Experience Level Name", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const QuestionSetColumns = [
  { field: "set_name", headerName: "Set Name", width: 190 },
  //{ field: "no_of_question", headerName: "No Of Question", width: 170 },
  { field: "maximum_time", headerName: "Maximum Time (in min)", width: 180 },
];

export const QuestionColumns = [
  {
    field: "title", headerName: "Title", width: 200, renderCell: (params) => {
      return (
        <Tooltip title={params.row.title}>
          <div className="cellWithStatus">
            {params.row.title}
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "description", headerName: "Description", width: 200, renderCell: (params) => {
      return (
        <Tooltip title={params.row.description}>
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip>
      );
    },
  },
  { field: "marks", headerName: "Marks", width: 90 },
];
//columns heading for  Industry

export const IndustryColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "industry_name", headerName: "Industry Name", width: 230 },
  { field: "industry_type", headerName: "Industry Type", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];
export const QualificationColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "qualification_name", headerName: "Education Name", width: 230 },
]
export const ScheduleColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "schedule_name", headerName: "Schedule Name", width: 400, renderCell: (params) => {
      return (
        <Tooltip title={params.row.schedule_name}>
          <div className="cellWithStatus">
            {params.row.schedule_name}
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "batch_id", headerName: "Batch", width: 400, renderCell: (params) => {
      return (
        <Tooltip title={params?.row?.batch?.display_name}>
          <div className="cellWithStatus">
            {params?.row?.batch?.display_name}

          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "start_date", headerName: "Start Date", width: 160, type: "date",
    renderCell: (params) => {
      return (
        <div>{params.row.start_date ? (params.row.start_date).split('T')[0] : "-"}</div>
      )
    }
  },
  {
    field: "end_date", headerName: "Expiry Date", width: 160, type: "date",
    renderCell: (params) => {
      return (
        <div>{params.row.end_date ? (params.row.end_date).split('T')[0] : "-"}</div>
      )
    }
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const SkillColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "skill_name", headerName: "Skill Name", width: 230 },
  { field: "skill_key", headerName: "Skill Key", width: 230 },
  {
    field: "description", headerName: "Description", width: 300,
    renderCell: (params) => {
      return <div>{params.row.description ? params.row.description : "N/A"}</div>;
    }
  },

  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];
