import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const getAllThirdPartyList = createAsyncThunk("third-party/all-list", async (data) => {
    const res = await DataService.all_third_party_list(data);
    return res.data;
});

export const getThirdPartyList = createAsyncThunk("third-party/list", async (data) => {
    const res = await DataService.third_party_list(data);
    return res.data;
});

export const thirdPartyCreate = createAsyncThunk("third-party/create", async (data) => {
    const res = await DataService.third_party_create(data);
    return res.data;
});

export const thirdPartyEdit = createAsyncThunk("third-party/edit", async (id) => {
    const res = await DataService.third_party_edit(id);
    return res.data;
});

export const thirdPartyUpdate = createAsyncThunk("third-party/update", async (data) => {
    const res = await DataService.third_party_update(data.ID, data.addvalues);
    return res.data;
});

export const thirdPartyArchive = createAsyncThunk("third-party/archive", async (id) => {
    const res = await DataService.third_party_archive(id);
    return res.data;
});

export const getThirdPartyDomainList = createAsyncThunk("third-party/get-all-assign-list", async (data) => {
    const res = await DataService.third_party_domain(data);
    return res.data;
});

export const getDomainList = createAsyncThunk("get/all-domains", async (data) => {
    const res = await DataService.domain_list(data);
    const resp = res.data;
    return resp;
});

const thirdPartySlice = createSlice({
    name: "thirdparty",
    initialState: {
        thirdParty_AllList: [],
        thirdParty_List: [],
        thirdParty_Create: [],
        thirdParty_Edit: [],
        thirdParty_Update: [],
        thirdParty_Archive: [],
        thirdParty_Domain: [],
        domainList: [],
        status: STATUSES.IDLE,
        error: ''
    },
    reducers: {},
    extraReducers(builder) {
        builder
            ///all third party  list
            .addCase(getAllThirdPartyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllThirdPartyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.thirdParty_AllList = action.payload;
            })
            .addCase(getAllThirdPartyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            /// get filterd third party list
            .addCase(getThirdPartyList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getThirdPartyList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.thirdParty_List = action.payload;
            })
            .addCase(getThirdPartyList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })
            ///third party create
            .addCase(thirdPartyCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(thirdPartyCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_thirdParty_Create`;
                state.thirdParty_Create = action.payload;
            })
            .addCase(thirdPartyCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///third party edit
            .addCase(thirdPartyEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(thirdPartyEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.thirdParty_Edit = action.payload;
            })
            .addCase(thirdPartyEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///third party Update
            .addCase(thirdPartyUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(thirdPartyUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_thirdParty_Update`;
                state.thirdParty_Update = action.payload;
            })
            .addCase(thirdPartyUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///third party archive
            .addCase(thirdPartyArchive.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(thirdPartyArchive.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_thirdParty_Archive`;
                state.thirdParty_Archive = action.payload;
            })
            .addCase(thirdPartyArchive.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // get ThirdParty Domain 
            .addCase(getThirdPartyDomainList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getThirdPartyDomainList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.thirdParty_Domain = action.payload;
            })
            .addCase(getThirdPartyDomainList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            // GET DOMAIN LIST
            .addCase(getDomainList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getDomainList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.FAILURE : `${STATUSES.SUCCESS}_domainList`;
                state.error = !!action.payload.data;
                state.domainList = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.count = action.payload.data.count;
            })
            .addCase(getDomainList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })


    },
});

export default thirdPartySlice.reducer;