import React from 'react'
import "./setting.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";

const Setting = () => {
  return (
    <div className="setting">
        <Sidebar/>
        <div className="newContainer">
        <Navbar/>

        <p>This is setting page.</p>
        </div>
        
    </div>
  )
}

export default Setting
