import React, { useCallback, useRef, useState } from "react";
import "./candidatekyc.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Button} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const CandidateKyc = () => {
  const [photo, setPhoto] = useState(null);
  const [uniqueIDCard, setUniqueIDCard] = useState(null);
  const webcamRef = useRef(null);
  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef]);

  const captureUniqueIDCard = useCallback(() => {
    const uniqueIDCardImg = webcamRef.current.getScreenshot();
    setUniqueIDCard(uniqueIDCardImg);
  }, [webcamRef]);



  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>Complete your KYC</h3>
        </div>

        <div className="termand-condition-data" style={{ textAlign: "center" }}>
          <h4>Capture your photo</h4>
          <h5>Instruction</h5>
          <p>Make sure your face will proper visibile, straight and clear.</p>
          <div style={{ margin: "20px", textAlign: "left", display: "flex" }}>
            {photo && (
              <div className="capturedPhoto" style={{ margin: "20px", boxShadow : "2px 2px 15px 0px #e0e0e0", borderRadius: "6px", padding: "21px", textAlign: "center"}}>
                <h6>Captured Photo</h6>
                <img
                  src={photo}
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  alt="Captured Photo"
                />

                {uniqueIDCard && (
                  <>
                <h6 style={{ margin: "20px"}}>Captured PAN / AADHAAR</h6>
                <img
                  src={uniqueIDCard}
                  style={{ width: "200px", height: "200px", objectFit: "cover" }}
                  alt="Captured PAN / AADHAAR"
                />
                </>
                )}

              </div>
            )}
            {photo && uniqueIDCard && (
            <div>
              <h1 style={{ color: "green", marginTop: "176px", fontSize: "36px" }}>
                Your KYC successfully completed!
              </h1>
              <a href="/term-and-condition"><Button
                style={{ width: "250px", margin: "23px 178px" }}
                variant="contained"
              >
                Next
              </Button></a>
            </div>
          )} 
                                          
          </div>

          {(!photo || !uniqueIDCard) && (
            <Webcam
              audio={false}
              height={400}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={1000}
              mirrored={true}
              imageSmoothing={true}
              videoConstraints={videoConstraints}
            />
          )} 

          {!photo && (
            <Button
              style={{ width: "250px" }}
              onClick={capturePhoto}
              variant="contained"
              endIcon={<PhotoCamera />}
            >
              Capture Photo
            </Button>
          )} 

          {photo && !uniqueIDCard && (
            <Button
              style={{ width: "250px" }}
              onClick={captureUniqueIDCard}
              variant="contained"
              endIcon={<PhotoCamera />}
            >
              Capture Authorize ID
            </Button>
          )} 
        </div>
      </div>
    </div>
  );
};

export default CandidateKyc;
