import React, { useEffect, useState } from 'react'

import "./register.scss";
import Logo from "../../images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginWithOtp, registerVerification } from "../../redux/features/authSlice";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from '../../components/common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';

const formErrData = Object.freeze({
  emailErr: "Email is required",
  otpErr: "Email OTP is required"
});
const Verify = () => {
  const { REACT_APP_ENV } = process.env;
  const registerData = useLocation().state;

  const dispatch = useDispatch();
  const { status, error, registerVerify, loginWithOTP } = useSelector((state) => state.authUser)
  const [loader, setLoader] = useState(false)

  const [emailOtp, setEmailOtp] = useState('');
  const [mobileOtp, setMobileOtp] = useState('');

  const [formErrors] = useState(formErrData);
  const [errStatus, setErrStatus] = useState(false);
  const [, setValidateErr] = useState("")

  const [showLoginLink, setShowLoginLink] = useState(false);

  const navigate = useNavigate();

  //submit section 
  const handleVarification = (e) => {
    e.preventDefault();

    if (emailOtp === "") {
      setErrStatus(true)
    }

    if (emailOtp) {
      setErrStatus(false)

      const data = {
        email: registerData?.email,
        email_otp: emailOtp,
      }
      if (mobileOtp !== "") {
        data['mobile_otp'] = mobileOtp
      }
      setValidateErr("")
      setLoader(true)
      dispatch(registerVerification(data));

    }
  }

  // error handling
  if (loader === true && status === STATUSES.FAILURE && error && error.message) {
    setLoader(false)
    toast.error(error.message);
  }

  // register verify
  if (loader === true && status === `${STATUSES.SUCCESS}_registerVerify` && registerVerify && registerVerify.message) {
    if (registerVerify.status === 200) {
      setShowLoginLink(true)
      toast.success(registerVerify.message);
      setTimeout(() => {
        navigate('/')
      }, 3000)
    } else {
      toast.error(registerVerify.message)
    }
    setLoader(false)
  }

  //resend otp section
  const resendOtp = (e) => {
    e.preventDefault();
    const data = {
      email: registerData?.email,
    }
    setLoader(true)
    dispatch(loginWithOtp(data));

  }
  //resend otp response
  if (loader === true && status === `${STATUSES.SUCCESS}_loginWithOTP` && loginWithOTP && loginWithOTP.message) {
    if (loginWithOTP.status === 200) {
      toast.success(loginWithOTP.message);
    } else {
      toast.error(loginWithOTP.message)
    }
    setLoader(false)
  }

  useEffect(() => {
    const user = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
    if (user && user.token) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="signin">
      <div className="sign-in-row">
        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>
        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4 style={{ fontSize: "21px" }}>Candidate Registration Verification</h4>
          <ToastContainer />
          {loader && <LoaderForm />}

          <form>
            <div className="formInput">
              <span className='badge-section'>A One Time Passcode has been sent to <b>{registerData?.email}</b></span>
            </div>

            <div className="formInput">
              <label> Email OTP </label>
              <input
                name="email_otp"
                type="text"
                placeholder="Email OTP"
                value={emailOtp}
                onChange={(e) => setEmailOtp(e.target.value)}
              />
              {errStatus === true && formErrors && formErrors.otpErr && emailOtp === "" && <p className='error-message'>{formErrors.otpErr}</p>}
            </div>

            <div className="formInput">
              <span className='badge-section'>A One Time Passcode has been sent to <b>{registerData?.contact_no}</b></span>
            </div>

            <div className="formInput">
              <label> Mobile OTP </label>
              <input
                name="mobile_otp"
                type="text"
                placeholder="Mobile OTP"
                value={mobileOtp}
                onChange={(e) => setMobileOtp(e.target.value)}
              />
            </div>

            {
              showLoginLink === true ?
                <p className="text-right"><Link to={"/"} className="forgot-password-link">Click here to Login</Link></p>
                : ""
            }
            <div style={{ display: "flex" }}>
              <button
                onClick={handleVarification}
                type="submit"
                className={loader === true ? "submit-btn-disabled" : "submit-modal"}
                disabled={loader === true}
              >
                Verify OTP
              </button>
              <button type="submit"
                style={{ marginLeft: "10px" }}
                className={loader === true ? "submit-btn-disabled" : "verify-button-section"}
                disabled={loader === true}
                onClick={resendOtp}>
                Resend OTP
              </button>
            </div>
          </form>


        </div>
      </div>
    </div>
  )
}

export default Verify
