import * as React from 'react';
import "./evaluatePage.scss"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { TablePagination, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect,useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import { ArrowBack } from "@mui/icons-material";
import { getQusetionDetailsList } from '../../redux/features/evaluationSlice';
import Filter from '../../components/Filter';
import CustomeTooltip from '../../components/common-function/CustomeTooltip';
import { questionLevelListOption, technologyListOption } from '../../redux/features/optionsSlice';
import EvaluatePage from './EvaluatePage';
import { RefreshButton } from '../../components/common-function/RefreshButton';


const QusetionDetailsCandidateExam = () => {

    const dispatch = useDispatch();
    const { id, technology_id } = useParams()

    const { status, error, questionDetailsExam_List } = useSelector(state => state.evaluationSlice)
    const { technologys, questionlevels } = useSelector(state => state.dropDownOption)

    const [filterQuery, setFilterQuery] = useState(`{"filter":{"candidate_exam_schedule_id":{"value":${id},"operation":"eq"},"technology_id":{"value":${technology_id},"operation":"eq"}},"order_by":{"column":"-id"},"skip":0,"limit":10}`)
    const [reload, setReload] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [defaultValue, setDefaultValue] = useState({})

    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDivs, setActiveDivs] = useState("")
    const [activeClass, setActiveClass] = useState(false)
    const [scheduleStatus, setScheduleStatus] = useState("")
    const [viewDetailsData, setViewDetailsData] = useState({})

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    useEffect(() => {
        dispatch(getQusetionDetailsList(filterQuery))
        dispatch(technologyListOption())
        dispatch(questionLevelListOption())
        // eslint-disable-next-line
    }, [])

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQusetionDetailsList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQusetionDetailsList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionDetailsExam_List && questionDetailsExam_List.data && questionDetailsExam_List.data.rows && questionDetailsExam_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQusetionDetailsList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"filter":{"candidate_exam_schedule_id":{"value":${id},"operation":"eq"}},"skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }

    const activeDrawerDiv = (data) => {
        setActiveDivs(`story_${data.id}`)
        let shedule_status =data?.candidate_exam_schedule && data.candidate_exam_schedule.schedule_status
        setScheduleStatus(shedule_status)
        setViewDetailsData(data)
        if (storyDiv > 0) {
            setStoryDiv(data.id)
        } else {
            setStoryDiv(data.id);
        }
    }

    const actionColumn = [
        storyDiv > 0 ?
            {
                field: "",
                headerName: "",
                width: 140,
                hideable: false,
                filterable: false,
                sortable: false,
                renderCell: (params) => {
                    return (
                        <div className="cellAction" style={{ cursor: params.row.evaluation_status === 1 && "not-allowed" }}>
                            <button className="btn btn-submit"
                                style={{ backgroundColor: params.row.evaluation_status === 1 ? "gray" : '#3131f1', color: '#fff', padding: "8px", width: "100px" }}
                                onClick={() => activeDrawerDiv(params.row)}
                                disabled={params.row.evaluation_status === 1}
                            >{params.row.evaluation_status === 1 ? "Evaluated" : "Evaluate"}</button>
                        </div>
                    );
                },
            } :
            {
                field: "id", headerName: "Id", width: 100, hideable: false, filterable: false, sortable: false,
            },

        {
            field: "question_id", headerName: "Question", width: 320, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip
                            title={params?.row?.question && params.row.question.title ? params.row.question.title : 'N/A'}
                            data={params?.row?.question && params.row.question.title ? params.row.question.title : 'N/A'}
                        />
                    </div>
                )
            }
        },
        {
            field: "technology_id", headerName: "Technology", width: 250, type: "singleSelect", valueOptions: technologys,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip
                            title={params?.row?.technology && params.row.technology.name ? params.row.technology.name : 'N/A'}
                            data={params?.row?.technology && params.row.technology.name ? params.row.technology.name : 'N/A'}
                        />
                    </div>
                )
            }
        },
        {
            field: "question__question_level_id", headerName: "Question Level", width: 240, type: "singleSelect", valueOptions: questionlevels,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip
                            title={params?.row?.question && params.row.question.question_level && params.row.question.question_level.level_name ? params.row.question.question_level.level_name : 'N/A'}
                            data={params?.row?.question && params.row.question.question_level && params.row.question.question_level.level_name ? params.row.question.question_level.level_name : 'N/A'}
                        />
                    </div>
                )
            }
        },
        // {
        //     field: "evaluation_status", headerName: "Evaluation status", width: 170, type: 'singleSelect',
        //     valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
        //     renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus ${params.row.status}`}>
        //                 {params.row.evaluation_status === true ? params.row.evaluation_status : 'N/A'}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "status", headerName: "Status", width: 130, type: 'singleSelect',
            valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action", headerName: "Action", width: 140, hideable: false, filterable: false, sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction" style={{ cursor: params.row.evaluation_status === 1 && "not-allowed" }}>
                        <button className="btn btn-submit"
                            style={{ backgroundColor: params.row.evaluation_status === 1 ? "gray" : '#3131f1', color: '#fff', padding: "8px", width: "100px" }}
                            onClick={() => activeDrawerDiv(params.row)}
                            disabled={params.row.evaluation_status === 1}
                        >{params.row.evaluation_status === 1 ? "Evaluated" : "Evaluate"}</button>
                    </div>
                );
            },
        },
    ];

    // filter
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue === "is" ? 'eq' :
                                filterModel.items[0].operatorValue;
            let field = filterModel.items[0].columnField === 'resource' ? "resource_id" :
                filterModel.items[0].columnField === 'credentails_head' ? "credentails_head_id" :
                    filterModel.items[0].columnField;

            const filterData = `{"filter":{"${[field]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"},"candidate_exam_schedule_id":{"value":${id},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`
            setFilterQuery(filterData);
            setCurrentPage(0);
            dispatch(getQusetionDetailsList(filterData));

        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0);
            setFilterQuery(`{"filter":{"candidate_exam_schedule_id":{"value":${id},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`);
            dispatch(getQusetionDetailsList(`{"filter":{"candidate_exam_schedule_id":{"value":${id},"operation":"eq"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}}`));
            setDefaultValue("")
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "technology_id" ?
                <Filter options={technologys} onChange={handleFilterChange} defaultValue={defaultValue} />
                : fieldName === "question__question_level_id" ?
                    <Filter options={questionlevels} onChange={handleFilterChange} defaultValue={defaultValue} />
                    : ""
        );
    }

    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "technology_id" || col.field === "question__question_level_id") {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
            // eslint-disable-next-line
        }), [actionColumn]
    );

    return (
        <div className="task">
            <Sidebar />
            <div className="taskContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Question Details Exam List</h3>
                        <div>
                            <Tooltip title="Back">
                                <Link to={"/evaluation-exam-list"} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                            <RefreshButton api={getQusetionDetailsList} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    <div style={{ display: 'flex' }}>
                        <div style={storyDiv > 0 ? activeClass === true ? { width: "0%" } : { width: '30%' } : { width: '100%' }}
                        >
                            <DataGrid
                                className="datagrid style-datatable"
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                                rows={questionDetailsExam_List?.data && questionDetailsExam_List.data.rows ? questionDetailsExam_List.data.rows : []}
                                columns={columns}
                                pageSize={pageSize}
                                style={{ fontSize: 16 }}
                                onFilterModelChange={onFilterChange}
                                disableSelectionOnClick
                                components={{
                                    Pagination: CustomPagination,
                                }}
                                onSortModelChange={(sort) => handleSort(sort)}
                                // onRowClick={(row) => { navigate(`/view-question-details/${row.id}`) }}
                                filterMode="server"
                            />
                        </div>

                        {
                            storyDiv > 0 && (scheduleStatus === 7 || scheduleStatus === 3 || scheduleStatus === 6 || scheduleStatus === 5) &&
                            <div style={(storyDiv > 0) ? activeClass === true ? { width: '99%', marginTop: '-2.3%' } : { width: '69%', marginTop: '-2.3%' } : {}}>
                                {storyDiv > 0 &&
                                    <EvaluatePage
                                        rowId={storyDiv}
                                        hideDiv={setStoryDiv}
                                        activeDivs={activeDivs}
                                        setActiveClass={setActiveClass}
                                        activeClass={activeClass}
                                        listData={viewDetailsData}
                                        filterQuery={filterQuery}
                                        apiCallList={getQusetionDetailsList}
                                    />
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default QusetionDetailsCandidateExam