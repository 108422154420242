import React, { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import UcNavBar from './UcNavBar'
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import dataService from "../../redux/services/data.service";

const UcCodeEditor = (props) => {
  let skill = props.skill;
  let candidateExamData = props.candidateExamData;
  let questionId = props.questionId;
  let myAnswer = props.myAnswer;
  let programmingEvaluetionId = props.programmingEvaluetionId;
  let user_code = programmingEvaluetionId[questionId]?.userCode ? programmingEvaluetionId[questionId].userCode : '';
  let user_output = programmingEvaluetionId[questionId]?.userCode ? programmingEvaluetionId[questionId].userOutput : ''



  // State variable to set editors default language
  const [userLang,] = useState(skill);

  // State variable to set editors default theme
  const [userTheme, setUserTheme] = useState("vs-dark");

  // State variable to set editors default font size
  const [fontSize, setFontSize] = useState(20);

  // Loading state variable to show spinner
  // while fetching data
  const [loading, setLoading] = useState(false);

  // State variable to set users source code
  const [userCode, setUserCode] = useState(user_code);

  const { compileExamPost } = useSelector(state => state.candidate)

  const options = {
    fontSize: fontSize,
  };

  useEffect(() => {
    
    setUserCode((user_code)=>  user_code)
  }, [programmingEvaluetionId])

  if (loading === true && compileExamPost && compileExamPost.data && props.userOutput === "") {
    setLoading(false)
    let output = compileExamPost.data.output_data.output
    props.setProgrammingIds(compileExamPost.data.id, questionId, userCode, output)
    props.setUserOutput(compileExamPost.data.output_data.output);
    props.setUserCode(userCode)
    // setUserOutput(output)
  }

  const apiCall = async (data) => {
    const res = await dataService.exam_compile_post(data)
    const result = await res.data
    setLoading(false);
    let output = result.data.output_data.output
    let userCodeIs = props.userCode
    props.setProgrammingIds(result.data.id, questionId, userCodeIs, output)
    props.setUserOutput(result.data.output_data.output);
    //props.setUserCode(userCode)
  }

  // Function to call the compile endpoint
  function compile() {
    if (props.userCode === ``) {
      return;
    }
    let data = {
      "candidate": candidateExamData.candidate_id,
      "candidate_exam_schedule": candidateExamData.id,
      "question": questionId,
      "technology_key": skill,
      "code": props.userCode,
      'id': myAnswer[questionId] ? myAnswer[questionId] : ""

    }

    setLoading(true);
    //clearOutput()()
    apiCall(data)
    //disptach(postExamCompile(data))




    // Post request to compile endpoint
    // Axios.post(`http://localhost:8001/compile`, {
    //   code: userCode,
    //   userId: 2,
    //   skill: "javascript",
    //   language: userLang,
    //   input: userInput,
    // })
    //   .then((res) => {
    
    //     setUserOutput(res.data.output);
    //   })
    //   .then(() => {
    //     setLoading(false);
    //   });
  }

  // Function to clear the output screen
  function clearOutput() {
    props.setUserOutput("");
  }


  return (
    <div className="uc_code_editor" id="UcCodeEditor">
      <UcNavBar
        userLang={userLang} /*setUserLang={setUserLang}*/
        userTheme={userTheme} setUserTheme={setUserTheme}
        fontSize={fontSize} setFontSize={setFontSize}
      />
      <div className="main">
        <div className="left-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>
          <Editor
            options={options}

            width="100%"
            theme={userTheme}
            language={userLang}
            defaultLanguage="javascript"
            value={user_code}
            saveViewState={true}
            onChange={(value) => { props.setUserCode(value); }}
          />
          <button className="run-btn" onClick={() => compile()}>
            Run
          </button>
        </div>
        <div className="right-container" style={{ float: "left", width: "50%", height: "calc(50vh - 100px)" }}>

          <h4>Output:</h4>
          {loading ? (
            <div className="spinner-box">
              {/* <img src={spinner} alt="Loading..." /> */}
              <CircularProgress />
            </div>
          ) : (
            <div className="output-box">
              <pre>{user_output}</pre>
              <button onClick={() => { clearOutput() }}
                className="clear-btn">
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default UcCodeEditor;
