import http from "../APIS/api";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'

class DataService {

  create(data) {
    return http.post("/login", data);
  }


  userLogin(token) {
    return http.get("/user", {
      headers: {
        'Authorization': token,
        //'Cookie':`token=${token}`
      }
    });
  }

  logout() {
    return http.post("/logout");
  }

  //Department api
  departmentList(filterQuery) {
    return http.get(`/mst/departments?where=${filterQuery}`);
  }

  department_List(filterQuery) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}} ,"no_limit":"True","order_by":{"column":"-id"}}')

  }

  addDepartment(data) {
    return http.post("/mst/departments", data);
  }

  departmentById(id) {
    return http.get(`/mst/departments/${id}`);
  }

  update_department(id, data) {
    return http.put(`/mst/departments/${id}`, data);
  }
  updateDepartment(id, data) {
    return http.put(`/mst/departments/${id}`, data);
  }
  delete_department(id, data) {
    return http.delete(`/mst/departments/${id}`, data);
  }


  //role api
  roleList(filterQuery) {
    return http.get(`/mst/tms-roles?where=${filterQuery}`);
  }

  addRole(data) {
    return http.post("/mst/tms-roles", data);
  }

  roleById(id) {
    return http.get(`/mst/tms-roles/${id}`);
  }

  updateRole(id, data) {
    return http.put(`/mst/tms-roles/${id}`, data);
  }

  deleteRole(id, data) {
    return http.delete(`/mst/tms-roles/${id}`, data);
  }

  //user Type api
  userTypeList(filterQuery) {
    return http.get(`mst/user-type?where=${filterQuery}`);
  }

  addUserType(data) {
    return http.post("/mst/user-type ", data);
  }

  userTypeById(id) {
    return http.get(`/mst/user-type/${id}`);
  }

  updateUserType(id, data) {
    return http.put(`/mst/user-type/${id}`, data);
  }

  deleteUserType(id, data) {
    return http.delete(`/mst/user-type/${id}`, data);
  }

  country_list() {
    return http.get('mst/country')
  }

  ///resource list

  resource_list(filterQuery) {
    if (filterQuery) {
      return http.get(`evaluation/users/list?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get('/evaluation/users/list?where={"order_by":{"column":"name"},"no_limit":True}')
      } else {
        return http.get(`/evaluation/users/list?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True}`)
      }
    }
  }

  candidate_list(filterQuery) {
    if (filterQuery) {
      return http.get(`evaluation/candidate-registration?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get('/evaluation/candidate-registration?where={"order_by":{"column":"name"},"no_limit":True}')
      } else {
        return http.get(`/evaluation/candidate-registration?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True}`)
      }
    }
  }

  get_all_resource_list() {
    return http.get('/evaluation/users/list?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"order_by":{"column":"-id"}}')
  }

  get_all_candidate_list() {
    return http.get('/evaluation/candidate-registration?where={"filter":{"status":{"value":"True","operation": "eq"}},"order_by": { "column": "-id" },"no_limit":True}')
  }

  ///resource view by id

  resource_view(id) {
    return http.get(`/evaluation/candidate-registration/${id}`);
  }

  candidate_view(id) {
    return http.get(`/evaluation/candidate-registration/${id}`);
  }

  ///resource add

  resource_add(data) {
    return http.post("/evaluation/candidate-registration", data);
  }

  ///resource edit
  resource_edit(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  ///resource delete

  resource_delete(id, data) {
    return http.delete(`/evaluation/candidate-registration/${id}`, data);
  }

  candidate_delete(id, data) {
    return http.delete(`/evaluation/candidate-registration/${id}`, data);
  }

  update_candidate_file(data) {
    return http.post("/file/upload", data);

  }


  //all  role lis 
  all_role_list() {
    return http.get(`/mst/tms-roles?where={"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"-id"}, "no_limit":True}`);
  }

  // all depattment list

  all_department_list() {
    return http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"-id"}, "no_limit":True}`);
  }
  department_type_list() {
    return http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"-id"}, "no_limit":True}`);
  }

  tms_role_list(id) {
    return http.get(`/mst/tms-roles?where={"no_limit":True}`);
  }


  create_resources(data) {
    return http.post(`/evaluation/candidate-registration`, data);
  }
  create_candidate(data) {
    return http.post(`/evaluation/candidate-registration`, data);
  }

  update_resources(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  update_candidate(id, data) {
    return http.put(`/evaluation/candidate-registration/${id}`, data);
  }

  import_file_candidate(data) {
    return http.post(`/evaluation/candidate/import`, data);
  }

  download_samplefile_candidate() {
    return http.get(`/evaluation/candidate/download/sample`);
  }

  // ________ Question set Start_______________//
  all_questionSet_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/set-tech?where=${filterQuery}`);
    } else {
      return http.get(`/mst/set-tech?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True,"order_by":{"column":"-id"} }`);
    }
  }
  questionSet_list(filterQuery) {
    return http.get(`/mst/set-tech?where=${filterQuery}`);
  }
  questionSet_create(data) {
    return http.post(`/mst/set`, data);
  }
  questionSet_edit(id) {
    return http.get(`/mst/set-tech/${id}`);
  }
  questionSet_update(id, data) {
    return http.put(`/mst/set/${id}`, data);
  }
  questionSet_archive(id, data) {
    return http.delete(`/mst/set/${id}`, data);
  }
  // ________Question Set End_________________//

  // ________question-type Start_______________//
  all_QuestionType_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/question-type?where=${filterQuery}`);
    } else {
      return http.get(`/mst/question-type?where={"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"-id"}, "no_limit":True}`);
    }
  }
  questionType_list(filterQuery) {
    return http.get(`/mst/question-type?where=${filterQuery}`);
  }
  questionType_create(data) {
    return http.post(`/mst/question-type`, data);
  }
  questionType_edit(id) {
    return http.get(`/mst/question-type/${id}`);
  }
  questionType_update(id, data) {
    return http.put(`/mst/question-type/${id}`, data);
  }
  questionType_archive(id, data) {
    return http.delete(`/mst/question-type/${id}`, data);
  }
  // ________ question-type End_________________//

  // ________ batch starts here________________//

  batch_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/batch?where=${filterQuery}`);
    }
    return http.get(`/mst/batch`);
  }
  batch_list_technology_filter(data) {
    return http.post(`/mst/batch-tech`, data);
  }
  batch_create(data) {
    return http.post(`/mst/batch`, data);
  }
  batch_edit(id) {
    return http.get(`/mst/batch/${id}`);
  }
  batch_update(id, data) {
    return http.put(`/mst/batch/${id}`, data);
  }
  batch_by_id(id) {
    return http.get(`/mst/batch/${id}`);
  }
  batch_by_tech(data) {
    return http.post(`/mst/batch-tech`, data);
  }
  // ________ batch ends here________________//

  // ________technology Start_______________//
  all_technology_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/technology?where=${filterQuery}`);
    } else {
      return http.get(`/mst/technology?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }
  technology_list(filterQuery) {
    return http.get(`/mst/technology?where=${filterQuery}`);
  }
  technology_create(data) {
    return http.post(`/mst/technology`, data);
  }
  technology_edit(id) {
    return http.get(`/mst/technology/${id}`);
  }
  technology_update(id, data) {
    return http.put(`/mst/technology/${id}`, data);
  }
  technology_archive(id, data) {
    return http.delete(`/mst/technology/${id}`, data);
  }
  // ________technology End_________________//

  // _________QuestionLevel Start_______________//
  all_questionlevel_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/question-level?where=${filterQuery}`);
    } else {
      return http.get(`/mst/question-level?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }
  questionlevel_list(filterQuery) {
    return http.get(`/mst/question-level?where=${filterQuery}`);
  }
  questionlevel_create(data) {
    return http.post(`/mst/question-level`, data);
  }
  questionlevel_edit(id) {
    return http.get(`/mst/question-level/${id}`);
  }
  questionlevel_update(id, data) {
    return http.put(`/mst/question-level/${id}`, data);
  }
  questionlevel_archive(id, data) {
    return http.delete(`/mst/question-level/${id}`, data);
  }
  // ________questionlevel End_________________//

  // _________Question Start_______________//
  all_question_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/evaluation/questions?where=${filterQuery}`);
    } else {
      return http.get(`/mst/evaluation/questions?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }
  question_list(filterQuery) {
    return http.get(`/evaluation/questions?where=${filterQuery}`);
  }
  question_create(data) {
    return http.post(`/evaluation/questions`, data);
  }
  question_edit(id) {
    return http.get(`/evaluation/questions/${id}`);
  }
  question_update(id, data) {
    return http.put(`/evaluation/questions/${id}`, data);
  }
  question_archive(id, data) {
    return http.delete(`/evaluation/questions/${id}`, data);
  }

  import_file_question(data) {
    return http.post(`/evaluation/questions/import`, data);
  }

  download_samplefile_question() {
    return http.get(`/evaluation/questions/download/sample`);
  }
  // ________question End_________________//

  //__________answer start____________//

  answer_option_list(filterQuery) {
    return http.get(`/evaluation/answer-option?where=${filterQuery}`);
  }
  answer_option_create(data) {
    return http.post(`/evaluation/answer-option`, data);
  }
  answer_option_update(id, data) {
    return http.put(`/evaluation/answer-option/${id}`, data);
  }
  answer_option_delete(id, data) {
    return http.delete(`/evaluation/answer-option/${id}`, data);
  }

  //__________answer end_______________//

  //___________ candidate Start___________//
  candidate_exam_schedule_list(filterQuery) {
    return http.get(`/evaluation/candidate/exam-schedule?where=${filterQuery}`);
  }
  candidate_exam_schedule_create(data) {
    return http.post(`/evaluation/candidate/exam-schedule`, data);
  }
  candidate_exam_schedule_edit(id) {
    return http.get(`/evaluation/candidate/exam-schedule/${id}`);
  }
  candidate_exam_schedule_update_status(id, data) {
    return http.put(`/evaluation/candidate/exam-schedule-status/${id}`, data);
  }
  candidate_exam_schedule_archive(id, data) {
    return http.delete(`/evaluation/candidate/exam-schedule/${id}`, data);
  }
  //___________candidate end _____________//

  //___________Exam Question start_______//


  get_exam_questions(schedule_id) {
    return http.get(`/evaluation/questions/get-questions/${schedule_id}`);
  }

  get_exam_schedule_status(schedule_id, data) {
    return http.put(`/evaluation/candidate/exam-schedule-status/${schedule_id}`, { schedule_status: data });
  }

  exam_compile_post(data) {
    return http.post(`/evaluation/program-evalutions`, data);
  }

  exam_compile_update(id, data) {
    return http.put(`/evaluation/program-evalutions/${id}`, data);
  }

  exam_post_submit(data) {
    return http.post(`/evaluation/candidate/exam-data`, data);
  }

  //___________Exam Question End_________//

  //candidate registration without-auth
  register(data) {
    return http.post("/crons/candidate-registration-without-authorization", data);
  }

  //candidate registration verification without-auth
  register_vervify(data) {
    return http.post("/crons/verify-registration-otp", data);
  }

  //send otp for login
  login_with_otp(data) {
    return http.post("/evaluation/sent-otp", data);
  }

  //___________________master schedule api______________//
  schedule_create(data) {
    return http.post("/mst/exam-schedule", data);
  }
  schedule_update(id, data) {
    return http.put(`/mst/exam-schedule/${id}`, data);
  }
  schedule_get_by_id(id) {
    return http.get(`/mst/exam-schedule/${id}`);
  }
  schedule_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/exam-schedule?where=${filterQuery}`);
    } else {
      return http.get(`/mst/exam-schedule?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }
  //___________________end api_________________________// 

  qualification_list(filterQuery) {
    return http.get(`/mst/qualification?where=${filterQuery}`);
  }
  qualification_create(data) {
    return http.post(`/mst/qualification`, data);
  }
  qualification_edit(id) {
    return http.get(`/mst/qualification/${id}`);
  }
  qualification_update(id, data) {
    return http.put(`/mst/qualification/${id}`, data);
  }
  qualification_archive(id, data) {
    return http.delete(`/mst/qualification/${id}`, data);
  }
  // ________qualification End_________________//

  // ________industry Start_______________//
  all_industry_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"order_by":{"column":"-id"}}`);
    }

  }
  industry_list(filterQuery) {
    return http.get(`/mst/industry?where=${filterQuery}`);
  }
  industry_create(data) {
    return http.post(`/mst/industry`, data);
  }
  industry_edit(id) {
    return http.get(`/mst/industry/${id}`);
  }
  industry_update(id, data) {
    return http.put(`/mst/industry/${id}`, data);
  }
  industry_archive(id, data) {
    return http.delete(`/mst/industry/${id}`, data);
  }
  // ________industry End_________________//

  // ________ schedule Start_______________//
  all_schedule_list_by_candidate(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/schedule-by-candidate/${filterQuery}`);
    } else {
      return http.get(`/mst/schedule-by-candidate?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True,"order_by":{"column":"-id"} }`);
    }
  }

  // _____candidate exam list based on experience, and technology_______//
  all_candidate_exam_list_by_EXT(filterQuery) {
    if (filterQuery) {
      return http.get(`/evaluation/candidate-exam-schedule?where=${filterQuery}`);
    } else {
      return http.get(`/evaluation/candidate-exam-schedule?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }

  // __________ Evaluation setction Start _______________//
  all_pending_evaluation_exam_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/evaluation/ces-evaluator?where=${filterQuery}`);
    } else {
      return http.get(`/evaluation/ces-evaluator?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }

  question_details_exam_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/evaluation/program-evalutions?where=${filterQuery}`);
    } else {
      return http.get(`/evaluation/program-evalutions?where={"filter":{"status":{"value":"True","operation": "eq"}}, "no_limit":True,"order_by":{"column":"-id"}}`);
    }
  }

  check_program_by_id(id) {
    return http.get(`/evaluation/evaluation-file-content/${id}`);
  }

  save_program_data(id, data) {
    return http.put(`/evaluation/programming-marks/${id}`, data);
  }

  // __________ Evaluation setction end _______________//

  // ________related skill Start_______________//
  all_related_skill_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/skills?where=${filterQuery}`);
    } else {
      return http.get(`/mst/skills?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"order_by":{"column":"-id"}}`);
    }

  }
  related_skill_list(filterQuery) {
    return http.get(`/mst/skills?where=${filterQuery}`);
  }
  related_skill_create(data) {
    return http.post(`/mst/skills`, data);
  }
  related_skill_edit(id) {
    return http.get(`/mst/skills/${id}`);
  }
  related_skill_update(id, data) {
    return http.put(`/mst/skills/${id}`, data);
  }
  related_skill_archive(id, data) {
    return http.delete(`/mst/skills/${id}`, data);
  }
  // ________related skill End_________________//

  // ________Industry - Department Start_______________//

  all_industry_department_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry-departments?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry-departments?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"order_by":{"column":"-id"}}`);
    }

  }
  industry_department_list(filterQuery) {
    return http.get(`/mst/industry-departments?where=${filterQuery}`);
  }
  industry_department_create(data) {
    return http.post(`/mst/industry-departments`, data);
  }
  industry_department_edit(id) {
    return http.get(`/mst/industry-departments?where={"filter":{"industry_id":{"value": ${id},"operation": "eq"},"status":{"value": "True","operation": "eq"}}}`);
  }
  industry_department_update(id, data) {
    return http.put(`/mst/industry-departments/${id}`, data);
  }
  industry_department_archive(id, data) {
    return http.delete(`/mst/industry-departments/${id}`, data);
  }
  // ________Industry - Department End_________________//

  // ________industry-Department-Specialisation Start_______________//

  all_industryDepartmentSpecialisation_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry-department-specialization-map?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"order_by":{"column":"-id"}}`);
    }

  }
  industryDepartmentSpecialisation_list(filterQuery) {
    return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
  }
  industryDepartmentSpecialisation_create(data) {
    return http.post(`/mst/industry-department-specialization-map`, data);
  }
  industryDepartmentSpecialisation_edit(id) {
    return http.get(`/mst/industry-department-specialization-map/${id}`);
  }
  industryDepartmentSpecialisation_update(id, data) {
    return http.put(`/mst/industry-department-specialization-map/${id}`, data);
  }
  industryDepartmentSpecialisation_archive(id, data) {
    return http.delete(`/mst/industry-department-specialization-map/${id}`, data);
  }
  // ________industry-Department-Specialisation End_________________//
  // ________Industry - Department-tech-skill End_________________//

  indus_dep_tech_skill_list(filterQuery) {
    return http.get(`/mst/industry-department-technology-skill?where=${filterQuery}`);
  }
  indus_dep_tech_skill_create(data) {
    return http.post(`/mst/industry-department-technology-skill`, data);
  }
  indus_dep_tech_skill_edit(id) {
    return http.get(`/mst/industry-department-technology-skill/${id}`);
  }
  indus_dep_tech_skill_update(id, data) {
    return http.put(`/mst/industry-department-technology-skill/${id}`, data);
  }
  indus_dep_tech_skill_archive(id, data) {
    return http.delete(`/mst/industry-departments-technology-skill/${id}`, data);
  }
  // ________Industry - Department-tech-skill End_________________//

  industry_depspecialization_list_data(filterQuery) {
    return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
  }

  // ________Test Type Start_________________//

  test_type_list() {
    return http.get(`/mst/test-type`);
  }

  test_type_create(data) {
    return http.post(`evaluation/question-test-types/assign`, data);
  }

  //--------------evalution candidate final submition----------//
  evaluator_result_final_submition(data) {
    return http.post(`/evaluation/final-result-submission`, data);
  }
    // result api
    get_result_api(filterQuery) {
      return http.get(`evaluation/get-candidate-exam-result?where=${filterQuery}`);
    }

}

// eslint-disable-next-line
export default new DataService();