import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import {Tooltip} from "@mui/material";
import { Link,useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import * as React from 'react';
import { ArrowBack } from "@mui/icons-material";
import { getCandidateResult } from "../../redux/features/candidateExamSlice";
import { questionLevelListOption, questionTypeListOption, setListOption, technologyListOption } from "../../redux/features/optionsSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import moment from "moment";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";


const CandidateExamResult = () => {

    const { id } = useParams()
    const dispatch = useDispatch();
    const { status, error, result_Data } = useSelector(state => state.candidate)
    const [filterQuery, setFilterQuery] = useState(`{"exam_schedule_id":${id}}`)
    const [pageSize,] = useState(10);
    const [limit,] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [actionColumn, setActionColumn] = useState([
        {
            field: "candidate_id", headerName: "Candidate Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row?.first_name} {params.row?.last_name}
                    </div>
                );
            }
        },
        {
            field: "exam_date", headerName: "Exam Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row?.marks_obtained}
                    </div>
                );
            }
        },
    ])

    useEffect(() => {
        dispatch(getCandidateResult(filterQuery))
        dispatch(technologyListOption())
        dispatch(questionTypeListOption())
        dispatch(questionLevelListOption())
        dispatch(setListOption())
        // eslint-disable-next-line
    }, [])

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
        }
        // eslint-disable-next-line 
    }, [error])

    //custom pagination

    const actionColumnAdmin = [
        {
            field: "candidate__first_name", headerName: "Candidate First Name", width: 230, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip
                            title={params.row?.first_name ? params.row?.first_name : "N/A"}
                            data={params.row?.first_name ? params.row?.first_name : "N/A"}
                        />
                    </div>
                );
            }
        },
        {
            field: "candidate__last_name", headerName: "Candidate Last Name", width: 230, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        <CustomeTooltip
                            title={params.row?.last_name ? params.row?.last_name : "N/A"}
                            data={params.row?.last_name ? params.row?.last_name : "N/A"}
                        />
                    </div>
                );
            }
        },
        {
            field: "exam_name", headerName: "Exam Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row?.exam_name ? params.row?.exam_name : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "exam_date", headerName: "Exam Date", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row?.exam_date}
                    </div>
                );
            }
        },
        {
            field: "technology", headerName: "Technology", width: 300, filterable: false, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <CustomeTooltip
                            title={params.row.technologies && params.row.technologies.length > 0 ? params.row.technologies.map((element) => element.name).filter((name) => name).join(', ') : "-"}
                            data={params.row.technologies && params.row.technologies.length > 0 ? params.row.technologies.map((element) => element.name).filter((name) => name).join(', ') : "-"}
                        />
                    </div>
                );
            }
        },
        {
            field: "mark_obtained", headerName: "Marks Obtained", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.marks_obtained ? params.row.marks_obtained : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "total_mark", headerName: "Total Mark", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.total_marks ? params.row.total_marks : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "percentage", headerName: "Percentage", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.percentage ? params.row.percentage : "N/A"}
                    </div>
                );
            }
        },
    ];

    const actionColumnCandidate = [
        {
            field: "exam_name", headerName: "Exam Name", width: 230, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row?.exam_name ? params.row?.exam_name : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "exam_date", headerName: "Exam Date", width: 200, filterable: false, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {moment(params.row.scheduled_date).format('DD-MM-YYYY')}
                    </div>
                );
            },
        },
        {
            field: "technology", headerName: "Technology", width: 300, filterable: false, renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <CustomeTooltip
                            title={params.row.technologies && params.row.technologies.length > 0 ? params.row.technologies.map((element) => element.name).filter((name) => name).join(', ') : "-"}
                            data={params.row.technologies && params.row.technologies.length > 0 ? params.row.technologies.map((element) => element.name).filter((name) => name).join(', ') : "-"}
                        />
                    </div>
                );
            }
        },
        // {
        //     field: "total_mark", headerName: "Total Mark", width: 200, filterable: false, renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus`}>
        //                 {params.row.total_mark ? params.row.total_mark : "N/A"}
        //             </div>
        //         );
        //     }
        // },
        // {
        //     field: "mark_obtained", headerName: "Marks Obtained", width: 220, filterable: false, renderCell: (params) => {
        //         return (
        //             <div className={`cellWithStatus`}>
        //                 {params.row.marks_obtained ? params.row.marks_obtained : "N/A"}
        //             </div>
        //         );
        //     }
        // },
        {
            field: "percentage", headerName: "Percentage", width: 200, filterable: false, renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.percentage ? params.row.percentage : "N/A"}
                    </div>
                );
            }
        },
        {
            field: "status", headerName: "Status Pass/Fail", width: 110, type: 'singleSelect',
            valueOptions: [{ label: "Active", value: "True" }, { label: "Inactive", value: "False" }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {params.row.final_result ? params.row.final_result : 'N/A'}
                    </div>
                );
            },
        }
    ];

    useEffect(() => {
        const userRole = GetLocalstorage.userDetail().tms_role.role_key
        if (userRole === "admin") {
            setActionColumn(actionColumnAdmin)
        } else if (userRole === "evaluator") {
            setActionColumn(actionColumnAdmin)
        } else if (userRole === "candidate") {
            setActionColumn(actionColumnCandidate)
        }
       

    }, [GetLocalstorage.userDetail().tms_role.role_key])


    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "is" ? 'eq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
           
            dispatch(getCandidateResult(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
          
            setFilterQuery(`{"exam_schedule_id":${id}}`)
            dispatch(getCandidateResult(`{"exam_schedule_id":${id}}`))
        }
    }

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Candidate Result</h3>
                        <div>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={result_Data?.data ? result_Data.data : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                    // disableSelectionOnClick
                    // components={{
                    //     Pagination: CustomPagination,
                    // }}
                    // onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>
            </div>
        </div >
    )
}

export default CandidateExamResult