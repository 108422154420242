import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, ArrowBack, Close } from "@mui/icons-material";
import { InputFields, InputMultiSelectField, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { getQuestionSetList, getQuestionTypeList, questionSetArchive, questionSetCreate, questionSetEdit, questionSetUpdate, getIndustryDepartmentList, getIndustryList, getIndusDepSpecListData } from "../../redux/features/masterSlice";
import { questionLevelListOption, setListOption, technologyListOption, } from "../../redux/features/optionsSlice";
import Filter from "../../components/Filter";
import Select from 'react-select';
import { timeHoursConvert, timeMinutConvert } from "../../components/util/CalculationTime";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import CustomeTooltip from "../../components/common-function/CustomeTooltip";
import { getDepartmentList } from "../../../src/redux/features/departmentSlice";



const getUnique = (arr, index) => {

    const unique = arr
        .map(e => e[index])

        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)

        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

    return unique;
}
const initialFormData = Object.freeze({
    set_name: "",
    technology: "",
    department: "",
    industry: "",
    level: "",
    no_of_question: "",
    maximum_time: "",
    status: "",
    question_type_details: { mcq: '', programming: '' }
});

const formErrData = Object.freeze({
    setNameErr: "Set name is required",
    technologyErr: "Technology is required",
    departmentErr: "Department is required",
    industryErr: "Industry is required",
    levelErr: "Experience level is required",
    noOfQuestionErr: "Number of question is required",
    maximumTimeErr: "Test duration is required",
    statusErr: "Status is required"
});

let hoursList = [];
for (let i = 0; i < 24; i++) {
    hoursList.push({ value: i, label: i });
}

let minuteList = [{ value: 0, label: "0" }];
let gap = 5;
for (let i = 1; i <= 11; ++i) {
    minuteList.push({ value: i * gap, label: i * gap });
}

const QuestionSet = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, questionset_List, questionset_Create, questionset_Edit, questionset_Update, questionset_Archive, questiontype_List, industry_List, department_List, industryDepSpec_List_data } = useSelector(state => state.master);
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":10}`)
    const [pageSize, setPageSize] = useState(10);
    const [limit, setLimit] = useState(10);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const { technology, technologys, questionlevel, sets } = useSelector(state => state.dropDownOption)
    const [updatedtechnologyList, setupdatedtechnologyList] = useState([])


    const [filterTechnologyList, setFilterTechnologyList] = useState([]);
    const [filterQuestionLevelList, setFilterQuestionLevelList] = useState([]);
    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({ status: [{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }] })
    const [defaultValue, setDefaultValue] = useState({})

    const [noOfMcqQuestion, setNoOfMcqQuestion] = useState('');
    const [noOfProgrammingQuestion, setNOfProgrammingQuestion] = useState('');
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [technologies, setTechnologies] = useState([])
    const [filterQuestionTypeList, setFilterQuestionTypeList] = useState(false)
    const [filterSetList, setFilterSetList] = useState([])

    const [qtyType, setQtyType] = useState([])

    if (filterTechnologyList && filterTechnologyList.length === 0 && technology) {
        let array = []
        technologys?.map(e => { return array.push({ value: e.id, label: e.name }) })
        array.length > 0 && setFilterTechnologyList(array)
        array.length > 0 && setOptions({ ...options, technology__id: array })
    }

    if (filterQuestionLevelList && filterQuestionLevelList.length === 0 && questionlevel) {
        let array = []
        questionlevel?.map(e => { return array.push({ value: e.id, label: e.level_name }) })
        array.length > 0 && setFilterQuestionLevelList(array)
        array.length > 0 && setOptions({ ...options, level__id: array })
    }

    if (filterQuestionTypeList && filterQuestionTypeList === false && questiontype_List) {
        let array = []
        questiontype_List?.data && questiontype_List.data.rows && questiontype_List.data.rows.map(e => { return array.push({ value: e.id, label: e.question_type }) })
        array.length > 0 && setFilterQuestionTypeList(array)
        array.length > 0 && setOptions({ ...options, type_id: array })
    }


    if (filterSetList && filterSetList.length === 0 && sets) {
        let array = []
        sets?.length > 0 && sets.map(e => { return array.push({ value: e.id, label: e.set_name }) })
        array.length > 0 && setFilterSetList(array)
        array.length > 0 && setOptions({ ...options, set__id: array })
    }

    //error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        dispatch(getQuestionSetList(filterQuery))
        dispatch(questionLevelListOption())
        dispatch(technologyListOption(`{"filter": { "status": { "value": "True", "operation": "eq" } }, "order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(setListOption())
        dispatch(getQuestionTypeList(`{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"-id"}, "no_limit":True}`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))

        dispatch(getDepartmentList())


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0) }
        setOpen(false);
        setFormValues(initialFormData);
        setErrStatus(false)
        setNoOfMcqQuestion('')
        setNOfProgrammingQuestion('')
        setMinutes(0)
        setHours(0)
        setTechnologies([])
        setQtyType([])
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionset_Update` && questionset_Update && questionset_Update.message) {
        if (questionset_Update.status === 200) {
            toast.success(questionset_Update.message)
            dispatch(getQuestionSetList(filterQuery))
            handleClose()
        } else {
            toast.error(questionset_Update.message)
        }
        setReload(false)
    }

    if (reload === "submit" && status === `${STATUSES.SUCCESS}_questionset_Create` && questionset_Create && questionset_Create.message) {
        if (questionset_Create.status === 200) {
            toast.success(questionset_Create.message)
            dispatch(getQuestionSetList(filterQuery))
            handleClose()
        } else {
            toast.error(questionset_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_questionset_Archive` && questionset_Archive && questionset_Archive.message) {
        if (questionset_Archive.status === 200) {
            toast.success(questionset_Archive.message)
            dispatch(getQuestionSetList(filterQuery))
        } else {
            toast.error(questionset_Archive.message)
        }
        setReload(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const prepaireQuestionType = (items) => {
        let object = {}

        // eslint-disable-next-line
        items.map(e => {
            object = Object.assign({ ...object, [e.type_name]: e.type_value })
            return object

        })
        return object
    }
    const handleChangeIndustry = (evt) => {

        const { name, value } = evt.target || evt;
        if (evt) {
            if (name === 'industry') {
                dispatch(getIndustryDepartmentList(`{"filter":{"status": {"value": "True", "operation": "eq"},"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                setFormValues({ ...formValues, [name]: evt, "department": "", technology: "" })
                setupdatedtechnologyList([])
                setTechnologies([])
                setQtyType([])
            } else if (name === 'department') {
                // dispatch(getTechnologyList(`{"filter":{"industry_id":{"value":${value},"operation":"eq"}},"no_limit":True}`))
                const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
                    if (evt?.value === options?.department_id) {
                        return options?.specialization_data
                    } else {
                        return [];
                    }
                }).flat()
                setupdatedtechnologyList(speclist || [])
                setFormValues({ ...formValues, [name]: evt, "technology": "" });
            } else {
                setFormValues({ ...formValues, [name]: value });
            }

        }
    }

    const submitData = (e) => {
        e.preventDefault()
        if (formValues.set_name === "" || formValues.technology === "" || formValues.level === "" || (hours === 0 && minutes === 0)
            || (noOfMcqQuestion === "" && noOfProgrammingQuestion === "") || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.set_name && technologies.length > 0 && ((hours > 0 || hours.value > 0) || (minutes > 0 || minutes.value > 0)) && formValues.status) {
            let new_array = []
            // eslint-disable-next-line
            technologies.map(item => {
                new_array.push({
                    "id": item.id ? item.id : "",
                    "technology": item.technology_id,
                    "question_type_details": prepaireQuestionType(item.question_type_details),
                    "level": item.level

                })
            })
            setErrStatus(false)
            setReload("submit")
            let hours_value =  hours?.value ? parseInt(hours.value) : parseInt(hours)

            let min_value;
            if (ID) {
                if ( minutes?.value) min_value = parseInt(minutes.value)
                else if (minutes > 0 && !minutes.value) min_value = parseInt(minutes)
                else if (minutes === 0 && !minutes.value) min_value = minutes
                else min_value = parseInt(minutes.value)
            } else {
                min_value = minutes?.value ? parseInt(minutes.value) : parseInt(minutes)
            }
            const total_time = parseInt(hours_value) > 0 ? hours_value * 60 + min_value : min_value
            let addvalues = ({
                set_name: formValues.set_name,
                status: formValues.status,
                maximum_time: total_time,
                technologies: new_array,
                department: formValues?.department?.value ?? undefined,
                industry: formValues?.industry?.value ?? undefined,
                // industry: formValues.industry && formValues.industry.label ? formValues.industry.value : formValues.industry,
                // department: formValues.department && formValues.department.label ? formValues.department.value : formValues.department,

            })

            if (ID) {
                addvalues['updated_by'] = GetLocalstorage.userDetail().id;
                dispatch(questionSetUpdate({ ID, addvalues }))
            } else {
                addvalues['created_by'] = GetLocalstorage.userDetail().id
                dispatch(questionSetCreate(addvalues))

            }
        } else {
            setErrStatus(true)

        }
    }

    const selectValueTechnologyLable = (id) => {
        let data = technology ;
        let newObject = {}
        data && data.length > 0 && data.forEach(x => {
            if (x.id === id) (newObject = { value: x.id, label: x.name, name: x.name, technology_key: x.technology_key, id: x.id })
        })

        return newObject

    }

    const selectValueQuestionLevelLable = (id) => {
        let data = questionlevel && questionlevel;
        let newObject = null
        // eslint-disable-next-line
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.level_name, name: "level" })
        })
        return newObject
    }
    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && questionset_Edit && questionset_Edit.data) {
        let editData = questionset_Edit.data[0]
        let technolgyArray = []
        let hoursTime = timeHoursConvert(editData.maximum_time)
        let minTime = timeMinutConvert(editData.maximum_time)
        //let level = ""
        setHours({ label: hoursTime, value: hoursTime })
        setMinutes({ label: minTime, value: minTime })
        if (editData.technology && editData.technology.length > 0) {
            let array = []
            let array2 = []
            // eslint-disable-next-line
            editData.technology.map((e, i) => {
                technolgyArray.push(selectValueTechnologyLable(e.id))
                //level = selectValueQuestionLevelLable(e.level.id)
                let question_type_details = []
                if (e.question_type_details) {
                    let myObject = e.question_type_details
                    Object.keys(myObject).forEach(function (key, index) {
                        let filter = questiontype_List?.data && questiontype_List.data.rows &&
                            questiontype_List.data.rows.filter(x => x.question_type_key === key)

                        if (filter.length > 0) {
                            let filterQuestionType = array2.filter((x, idx) => idx === i)
                            if (filterQuestionType.length > 0) {
                                let array3 = []
                                array3.push(filterQuestionType[0].question[0])
                                array3.push(filter[0])
                                // eslint-disable-next-line
                                array2.map(e => {
                                    if (e.parent_index === i) {
                                        e['question'] = array3
                                    }
                                })

                            } else {
                                array2.push({ parent_index: i, question: [filter[0]] })
                            }
                            question_type_details.push({
                                parent_index: i,
                                type: filter[0].question_type,
                                type_id: filter[0].id,
                                type_name: filter[0].question_type_key,
                                type_value: myObject[key]
                            })
                        }

                    });
                }
                array.push({
                    id: e.id,
                    technology_id: e.id,
                    technology: e.name,
                    level: e.level?.id,
                    question_type_details: question_type_details
                })


            })
            setTechnologies(array)
            setQtyType(array2)

        }
        // if (editData.technologies && editData.technologies.length > 0) {
        //     let array = []
        //     let array2 = []
        //     editData.technologies.map((e, i) => {
        //         technolgyArray.push(selectValueTechnologyLable(e.technology_id))
        //         let question_type_details = []
        //         if (e.question_type_details) {
        //             let myObject = e.question_type_details
        //             Object.keys(myObject).forEach(function (key, index) {
        //                 let filter = questiontype_List && questiontype_List.data && questiontype_List.data.rows &&
        //                     questiontype_List.data.rows.filter(x => x.question_type_key === key)
        //                     } else {
        //                         array2.push({ parent_index: i, question: [filter[0]] })
        //                     }
        //                     question_type_details.push({
        //                         parent_index: i,
        //                         type: filter[0].question_type,
        //                         type_id: filter[0].id,
        //                         type_name: filter[0].question_type_key,
        //                         type_value: myObject[key]
        //                     })
        //                 }

        //             });
        //         }
        //         array.push({
        //             id: e.id,
        //             technology_id: e.technology_id,
        //             technology: e.technology && e.technology.name,
        //             level: e.level_id,
        //             question_type_details: question_type_details
        //         })


        //     })
        //     setTechnologies(array)

        //     setQtyType(array2)

        // }
        const speclist = industryDepSpec_List_data?.data?.rows.map((options) => {
            if (editData?.department?.id === options?.department_id) {
                return options?.specialization_data
            } else {
                return [];
            }
        }).flat()
        setupdatedtechnologyList(speclist || [])
        setFormValues({
            set_name: editData.set_name,
            technology: technolgyArray,
            level: selectValueQuestionLevelLable(editData.level_id),
            no_of_question: editData.no_of_question,
            maximum_time: editData.maximum_time,
            question_type_details: editData.question_type_details,
            status: editData.status,
            department: { name: 'department', label: editData?.department?.name ?? "", value: editData?.department?.id ?? "" },
            industry: { name: 'industry', label: editData?.industry?.industry_name ?? "", value: editData?.industry?.id ?? "" }
        })

        if (editData?.question_type_details) {
            editData.question_type_details.mcq && setNoOfMcqQuestion(editData.question_type_details.mcq)
            editData.question_type_details.programming && setNOfProgrammingQuestion(editData.question_type_details.programming)
        } else {
            setNoOfMcqQuestion('')
            setNOfProgrammingQuestion('')
        }
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }


    const handleTechnolgyChange = (evt, index = null) => {
        const { name, value } = evt.target || evt;
        const valueArray = technologies.length > 0 && technologies.map((item, i) => i === index ?
            { ...item, [name]: value } : item)
        index !== null && setTechnologies([...valueArray])
    }

    const addMoreTechnology = (evt) => {
        let array = []
        setFormValues({ ...formValues, technology: evt })
        evt.map(e => {
            let filterLevel = technologies.filter(x => x.technology_id === e.id)
            return array.push({
                id: filterLevel.length > 0 ? filterLevel[0].id : "",
                technology: e.technology ? e.technology : e.name,
                technology_id: e.technology_id ? e.technology_id : e.id,
                level: filterLevel.length > 0 ? filterLevel[0].level : "",
                question_type_details: filterLevel.length > 0 ? filterLevel[0].question_type_details : []
            })
        })
        setTimeout(() => {
            setTechnologies(array)
        }, 500);

    }

    // const removeType = (i, parent_index) => {
    //     let array = technologies
    //     const filterTechnolgy = technologies.map((item, index) => {
    //         if (index === parent_index) {
    //             const updateDetails = item.question_type_details.filter((itm, idx) => idx !== i)
    //             item['question_type_details'] = updateDetails

    //         }
    //         return item
    //     })
    //     setTechnologies(filterTechnolgy)

    // }

    const addMoreType = (evt, i) => {
        let array = []
        let filterTechnolgy = technologies.filter((x, index) => index === i)
        setQtyType({ ...qtyType, [i]: { parent_index: i, question: evt } })

        evt.map(e => {
            let filterDetails = filterTechnolgy[0].question_type_details.filter(x => x.type_id === e.id)
            return array.push({
                type: e.question_type,
                type_id: e.id,
                parent_index: i,
                type_name: e.question_type_key,
                type_value: filterDetails.length > 0 ? filterDetails[0].type_value : "",
                status: true
            })
        })
        const updateTechnolyDetails = technologies.map((e, index) => {
            if (index === i) {
                e['question_type_details'] = array

            }
            return e
        })

        setTechnologies(updateTechnolyDetails)

    }

    const handleQuestionChange = (evt, index = null, parent_index = null) => {
        const { value } = evt.target || evt;
        const updateObject = technologies.length > 0 && technologies.map((item, i) => {
            if (i === parent_index) {
                if (item.question_type_details.length > 0) {
                    // eslint-disable-next-line
                    item.question_type_details.map((items, idx) => {
                        if (idx === index) {
                            items['type_value'] = value
                        }
                    })
                }
            }
            return item
        })

        setTimeout(() => {
            setTechnologies(updateObject)
        }, 500);
    }

    const handleClickEdit = (id) => {
        dispatch(questionSetEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (id) => {
        setReload(true)
        setArchiveOpen(false)
        dispatch(questionSetArchive(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(questionset_List?.data && questionset_List.data.rows && questionset_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            let orderByColumn = sort[0].field;

            if (sort[0].field === "maximum_time") {
                orderByColumn = "set__maximum_time";
            } else if (sort[0].field === "status_id") {
                orderByColumn = "set__status";
            }
            
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ? '-' + orderByColumn : orderByColumn
            };
            

            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
        } else {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['order_by'] = {
                "column": '-id'
            };
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getQuestionSetList(JSON.stringify(newFilterData)))
            // setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    const actionColumn = [
        {
            field: "set__id", headerName: "Set Name", width: 250, type: "singleSelect", valueOptions: filterSetList,
            renderCell: (params) => {
                return (
                    <CustomeTooltip
                        title={params.row.set_name}
                        data={params.row.set_name ? params.row.set_name : "N/A"}
                    />
                );
            },
        },
        { field: "maximum_time", headerName: "Maximum Time (in min)", width: 200, 
            renderCell: (params) => {
                return (
                    <CustomeTooltip
                        title={params.row.maximum_time}
                        data={params.row.maximum_time ? params.row.maximum_time : "N/A"}
                    />
                );
            },
        },
        {
            field: "technology__id", headerName: "Technology", width: 300, type: "singleSelect", valueOptions: filterTechnologyList,
            renderCell: (params) => {
                return (
                    <div className="ellips-css-data">
                        <CustomeTooltip
                            title={params.row.technology && params.row.technology.length > 0 ? getUnique(params.row.technology, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                            data={params.row.technology && params.row.technology.length > 0 ? getUnique(params.row.technology, 'id').map((e, index) => `${index ? ', ' : ''}${(e.name).trim()}`) : "-"}
                        />
                    </div>
                );
            },
        },
        {
            field: "level__id", headerName: "Experience Level", width: 300, type: "singleSelect", valueOptions: filterQuestionLevelList,
            renderCell: (params) => {
                return (
                    <div className="ellips-css-data">
                        <CustomeTooltip
                            title={params.row.technology && params.row.technology.length > 0 ? getUnique(params.row.technology, 'id').map((e, index) => `${index ? ', ' : ''}${e.level && (e.level.level_name).trim()}`) : params.row.level}
                            data={params.row.technology && params.row.technology.length > 0 ? getUnique(params.row.technology, 'id').map((e, index) => `${index ? ', ' : ''}${e.level && (e.level.level_name).trim()}`) : params.row.level}
                        />
                    </div>
                );
            },
        },
        {
            field: "status_id",
            headerName: "Status",
            width: 140,
            type: "singleSelect",
            valueOptions: [{ value: "True", label: 'Active' }, { value: "False", label: 'Inactive' }],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="question-level"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={handleArchive} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            const fieldToFilterKey = {
                maximum_time: "set__maximum_time",
                status_id: "set__status"
            };

            const fieldName = filterModel.items[0].columnField;
            const filterKey = fieldToFilterKey[fieldName] || fieldName;

            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    (filterModel.items[0].operatorValue === "is" || filterModel.items[0].operatorValue === "equals") ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            
            const filterData = `{"filter":{"${filterKey}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getQuestionSetList(filterData))
            console.log(filterModel.items[0].value)
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getQuestionSetList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            setDefaultValue("")
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = React.useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "technology__id" || col.field === "level__id" || col.field === 'set__id' || col.field === 'status') {
                return {
                    ...col,
                    filterOperators: getGridSingleSelectOperators()
                        .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                        .map((operator) => ({
                            ...operator,
                            InputComponent: operator.InputComponent
                                ? CustomFilterInputValue
                                : undefined
                        }))
                };
            }
            return col;
        }),
        // eslint-disable-next-line
        [actionColumn]
    );

    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Set List</h3>
                        <div>
                            <Tooltip title="Add New Set">
                                <button onClick={handleOpen} className="btn-add-new"><Add /> </button>
                            </Tooltip>
                            <Tooltip title="Back">
                                <Link to={-1} className="btn-add-new"><ArrowBack /> </Link>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={questionset_List?.data && questionset_List.data.rows ? questionset_List.data.rows : []}
                        columns={columns}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"lg"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "10px" }}>{ID ? 'Edit' : 'Add New'} Set</DialogTitle>

                    <DialogContent style={{ height: "700px" }}>
                        <form>
                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Set Name"}
                                    name="set_name"
                                    defaultValue={formValues.set_name}
                                    type="text"
                                    placeholder="Set Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.setNameErr}
                                />
                                <div className="formInput">
                                    <label >
                                        Select Industry
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Industry'}
                                        onChange={handleChangeIndustry}

                                        defaultValue={formValues.industry}
                                        value={formValues.industry}
                                        isSearchable={true}
                                        name={'industry'}
                                        options={
                                            industry_List?.data && industry_List.data.rows.map((option) => {

                                                return {
                                                    label: option.industry_name,
                                                    value: option.id,
                                                    name: 'industry'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                </div>
                                {
                                    formValues.industry ?
                                        <div className="formInput">

                                            <label >
                                                Select Department
                                            </label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                // divstyle={{ flexBasis: '50%' }}
                                                placeholder={'Select Department'}
                                                name={"department"}
                                                onChange={handleChangeIndustry}
                                                defaultValue={formValues.department}
                                                value={formValues.department}
                                                isSearchable={true}
                                                options={
                                                    department_List?.map((option) => {

                                                        return {
                                                            label: option.label,
                                                            value: option.value,
                                                            name: "department"
                                                        }
                                                    })



                                                }
                                            />
                                            {errStatus === true && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentErr}</p>}
                                        </div>
                                        : <></>
                                }
                                {/* <InputSelectField
                                    label={'Technology'}
                                    placeholder="Select Technology"
                                    defaultValue={formValues.technology}
                                    name={"technology"}
                                    onChange={handleChange}
                                    optionList={technology ? technology : []}
                                    optionLevel={['name']}
                                    optionValue={"id"}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                /> */}
                                <InputMultiSelectField
                                    divstyle={{ flexBasis: '100%' }}
                                    label={"Technology"}
                                    name={"technology"}
                                    placeholder={"Select Technology"}
                                    onRemove={addMoreTechnology}
                                    onSelect={addMoreTechnology}
                                    defaultValue={formValues.technology && formValues.technology.length === 0 ? [] : formValues.technology}
                                    errStatus={errStatus}
                                    formErr={formErr.technologyErr}
                                    optionList={updatedtechnologyList || []}
                                    optionLevel={['name']}
                                    optionValue={'id'}
                                />
                                {
                                    technologies.map((item, i) => {
                                        return (
                                            <div className="add-new-user-form" key={i} style={{ flexBasis: "100%" }}>
                                                <div style={{ display: "flex" }}>
                                                    <div className="formInput" style={{ width: '45%', margin: '10px' }}>
                                                        <label >
                                                            Technology - {i + 1}
                                                        </label>
                                                        <input
                                                            disabled
                                                            name={"technology"}
                                                            onChange={(e) => handleTechnolgyChange(e, i)}
                                                            type="text"
                                                            placeholder="Technology"
                                                            value={item.technology}
                                                        />
                                                    </div>

                                                    <div className="formInput" style={{ width: '45%', margin: '10px' }}>
                                                        <label >
                                                            Experience Level
                                                        </label>

                                                        <select
                                                            name={"level"}
                                                            onChange={(e) => handleTechnolgyChange(e, i)}
                                                            value={item.level}
                                                        >
                                                            <option value="">Select</option>
                                                            {questionlevel.map((e, i) => {
                                                                return (<option value={e.id} key={i}>{e.level_name}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <InputMultiSelectField
                                                    divstyle={{ margin: '10px' }}
                                                    label={"Question Type"}
                                                    name={"question"}
                                                    placeholder={"Question Type"}
                                                    onRemove={(e) => addMoreType(e, i)}
                                                    onSelect={(e) => addMoreType(e, i)}
                                                    defaultValue={qtyType[i] ? qtyType[i].question : []}
                                                    optionList={questiontype_List?.data && questiontype_List.data.rows ? questiontype_List.data.rows : []}
                                                    optionLevel={['question_type']}
                                                    optionValue={'id'}
                                                />
                                                {
                                                    item.question_type_details.map((items, j) => {
                                                        return (
                                                            <div className="add-new-user-form" style={{ width: "45%", alignItems: "center", margin: '10px' }} key={j}>

                                                                {/* <div className="formInput" style={{ flexBasis: '20%' }}>
                                                                    <label >
                                                                        Question Type
                                                                    </label>
                                                                    <input
                                                                        disabled
                                                                        name={"technology"}
                                                                        onChange={(e) => handleQuestionChange(e, j)}
                                                                        type="text"
                                                                        placeholder="Technology"
                                                                        value={items.type}
                                                                    />
                                                                </div> */}
                                                                <div className="formInput">
                                                                    <label>{items.type}</label>
                                                                    <input
                                                                        name="type_value"
                                                                        defaultValue={items.type_value ? Number(items.type_value) : ""}
                                                                        //value={items.type_value}
                                                                        type="number"
                                                                        placeholder="Number Of Question"
                                                                        onBlur={(e) => handleQuestionChange(e, j, i)}
                                                                        min="1"
                                                                    />
                                                                </div>
                                                                {/* <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                                    <RemoveCircleOutline style={{ color: "blue", cursor: "pointer" }} onClick={() => removeType(j, i)} />
                                                                </div> */}


                                                            </div>
                                                        )
                                                    })}

                                                {/* <div className="formInput" style={{ flexBasis: '20%' }}>
                                                    <label >
                                                        Question Type
                                                    </label>
                                                    <select
                                                        name={"experience_type"}
                                                        onChange={(e) => handleSkillChange(e, i)}
                                                        value={item.experience_type}
                                                    >
                                                        <option>Select</option>
                                                        {questionlevel.map((e, i) => {
                                                            return (<option value={e.id} key={i}>{e.level_name}</option>)
                                                        })}
                                                    </select>
                                                </div> */}

                                                {/* <div className="formInput" style={{ flexBasis: '8%', marginTop: '3%' }}>
                                                    <RemoveCircleOutline style={{ color: "red", cursor: "pointer" }} onClick={() => removeTechnology(i)} />
                                                </div> */}

                                            </div>
                                        )
                                    })
                                }
                                {/* <div className="formInput" style={{ flexBasis: "100%" }}>
                                    <InputSelectField
                                        label={'Experience Level'}
                                        placeholder="Select Experience Level"
                                        defaultValue={formValues.level}
                                        name={"level"}
                                        onChange={handleChange}
                                        optionList={questionlevel ? questionlevel : []}
                                        optionLevel={['level_name']}
                                        optionValue={"id"}
                                        errStatus={errStatus}
                                        formErr={formErr.levelErr}
                                    />
                                </div> */}
                                {/* <div className="formInput">
                                    <label>Number Of MCQ Question</label>
                                    <input
                                        name="no_of_mcq_question"
                                        value={noOfMcqQuestion}
                                        type="number"
                                        placeholder="Number Of MCQ Question"
                                        onChange={(e) => setNoOfMcqQuestion(e.target.value)}
                                        min="1"
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Number Of Programming Question</label>
                                    <input
                                        name="no_of_programming_question"
                                        value={noOfProgrammingQuestion}
                                        type="number"
                                        placeholder="Number Of Programming Question"
                                        onChange={(e) => setNOfProgrammingQuestion(e.target.value)}
                                        min="1"
                                    />
                                </div>

                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    {errStatus === true && formValues && (noOfMcqQuestion === "" && noOfProgrammingQuestion === "") && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.noOfQuestionErr}</p>}
                                </div> */}

                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    Test Duration
                                </div>
                                <div className="formInput">
                                    <label>Select Hours</label>
                                    <Select
                                        className="select"
                                        placeholder="Select Hours"
                                        defaultValue={hours}
                                        name={"level"}
                                        onChange={(e) => setHours(e.value)}
                                        options={hoursList}
                                    />
                                </div>
                                <div className="formInput">
                                    <label>Select Minutes</label>
                                    <Select
                                        className="select"
                                        placeholder="Select Minutes"
                                        defaultValue={minutes}
                                        name={"level"}
                                        onChange={(e) => setMinutes(e.value)}
                                        options={minuteList}
                                    />
                                </div>
                                <div className="formInput" style={{ flexBasis: "100%" }} >
                                    {errStatus === true && formValues && (hours === 0 && minutes === 0) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.maximumTimeErr}</p>}
                                </div>
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button
                                className={reload === "submit" ? "submit-btn-disabled" : "submit-modal"}
                                onClick={submitData}
                                disabled={reload === "submit"}
                            >
                                {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                            </button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={'Archive'}
                    paragraph={'Are you sure to archive question set'}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default QuestionSet