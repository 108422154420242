import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { SkillColumns } from "../../components/datatable-coulms/tableCoulmns";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { relatedSkillCreate, relatedSkillEdit, relatedSkillUpdate, getRelatedSkillList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/common-function/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const initialFormData = Object.freeze({
    skill_name: "",
    description: "",
    status: "",

});

const formErrData = Object.freeze({
    skill_nameErr: "Skill name is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required"
});


const RelatedSkills = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, related_skill_List, related_skill_Create, related_skill_Edit, related_skill_Update, related_skill_Archive } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25} `)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');

    useEffect(() => {
        dispatch(getRelatedSkillList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (reload === true && status === `${STATUSES.SUCCESS}_related_skill_Update` && related_skill_Update && related_skill_Update.message) {
        if (related_skill_Update.status === 200) {
            toast.success(related_skill_Update.message)
            dispatch(getRelatedSkillList(filterQuery))
        } else {
            toast.error(related_skill_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_related_skill_Create` && related_skill_Create && related_skill_Create.message) {
        if (related_skill_Create.status === 200) {
            toast.success(related_skill_Create.message)
            dispatch(getRelatedSkillList(filterQuery))
        } else {
            toast.error(related_skill_Create.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_related_skill_Archive` && related_skill_Archive && related_skill_Archive.message) {
        if (related_skill_Archive.status === 200) {
            toast.success(related_skill_Archive.message)
            dispatch(getRelatedSkillList(filterQuery))
        } else {
            toast.error(related_skill_Archive.message)
        }
        setReload(false)
    }


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { 
            setTimeout(()=>
            {
             setID(0); 
            },20)
            setFormValues(initialFormData) 
        } setOpen(false); setFormValues(initialFormData);
        setErrStatus(false)
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.skill_name === "" || formValues.description === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.skill_name !== "" && formValues.description !== "" && formValues.status !== "") {
            const ces_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
            // eslint-disable-next-line
            let userId = ces_user.user?.data && ces_user.user.data.id
            let addvalues = { ...formValues, created_by: userId }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(relatedSkillUpdate({ ID, addvalues }))
            } else {
                dispatch(relatedSkillCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && related_skill_Edit && related_skill_Edit.data) {
        let editData = related_skill_Edit.data
        setFormValues({
            skill_name: editData.skill_name,
            skill_key: editData.skill_key,
            description: editData.description,
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(relatedSkillEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }

    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            skill_name: data.skill_name,
            skill_key: data.skill_key,
            description: data.description,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(relatedSkillUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getRelatedSkillList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getRelatedSkillList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(related_skill_List?.data && related_skill_List.data.rows && related_skill_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getRelatedSkillList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": 10, "order_by": { "column": "-id" } }`)
        }
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="skill"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
                    const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${encodeURIComponent(filterModel.items[0].value)}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getRelatedSkillList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
            dispatch(getRelatedSkillList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
        }
    }
    return (
        <div className="master">
            <Sidebar />
            <div className="masterContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Skill List</h3>
                        <div>
                            <Tooltip title={"Add Related Skill"}>
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add
                                    />
                                </button>
                            </Tooltip>
                            {/* <RefreshButton api={getRelatedSkillList} filter={filterQuery} style={{ marginTop: 0 }} /> */}
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={related_skill_List?.data && related_skill_List.data.rows ? related_skill_List.data.rows : []}
                        columns={SkillColumns.concat(actionColumn)}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Related Skill</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-master-form">
                                <InputFields
                                    label={"Skill"}
                                    name="skill_name"
                                    defaultValue={formValues.skill_name}
                                    type="text"
                                    placeholder="Skill Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.skill_nameErr}
                                />
                                { formValues.id ?
                                    <InputFields
                                    label={"Skill Key"}
                                    name="skill_key"
                                    defaultValue={formValues.skill_key}
                                    type="text"
                                    placeholder="Skill Key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    // formErr={formErr.skill_keyErr}
                                /> : ""
                                }

                                <InputTextareaField
                                    label={"Description"}
                                    defaultValue={formValues.description}
                                    name="description"
                                    onChange={handleChange}
                                    rows="3"
                                    placeholder="Description"
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />

                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />

                            </div>
                           <button className="submit-modal" onClick={submitData} disabled={reload === true}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Industry`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default RelatedSkills