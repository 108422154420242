import React, { useEffect, useRef, useState } from "react";
import HorizontalLinearStepper from "./Test";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getExamQuestions,
  getStatusScheduleExam,
  completeCandidateExamPost
} from "../../redux/features/candidateExamSlice";
import moment from "moment";
import screenfull from "screenfull";
import UcCodeEditor from "../../components/UcCodeEditor/UcCodeEditor";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { STATUSES } from "../../redux/common-status/constant";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { CopyBlock, Snippet } from "react-code-blocks";



const useRestrictCopyPaste = (props) => {
  useEffect(() => {
    props.actions?.forEach((action) => {
      action && window.addEventListener(action, preventPaste);
    });
    return () => {
      props.actions.forEach((action) => {
        action && window.removeEventListener(action, preventPaste);
      });
    };
  }, [props.window, props.actions]);

  const preventPaste = (e) => {
    e.preventDefault();
  };
};


export default function HorizontalLinearStepper1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const candidateSelectedExam = useLocation();

  let ans = {
    candidate: {},
    exam_data: {
      start_date_time: "",
      end_date_time: "",
      total_answered_questions: 0,
      total_unanswered_question: 0,
      total_time_taken: 0,
      exam_schedule_id: 0,
      answers: {
        mcq: [],
        programming: [],
      },
    },
  };

  const [candidateGivenAnswers, setCandidateGivenAnswers] = useState(ans);
  const [iseExamStarted, setIsExamStarted] = useState(false);
  const [examDuration,] = useState(
    candidateSelectedExam.state.data.set.maximum_time
  );
  const [totalQuestions,] = useState(
    candidateSelectedExam.state.data.set.no_of_question
  );

  const [, setExamTimeRemaining] = useState(0);

  const [currentDateTime, setCurrentDateTime] = useState(
    moment().format("YYYY-MM-DD h:mm A")
  );
  const [examStartDateTime, setExamStartDateTime] = useState("");
  const { status, error, candidateSelectedExamQuestions, statusScheduleExam, examResultData } = useSelector(
    (state) => state.candidate
  );
  const theme = useTheme();
  const [steps, ] = useState([]);
  const [, setMaxSteps] = useState(0);
  const [stepLoader, setStepLoader] = useState(true);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [myAnswer, setMyAnswer] = useState({});
  const [score, setScore] = useState(0);
  const [finish, setFinish] = useState(false);
  const [, setShow] = useState(false);
  const [, setClickAnswer] = useState(false);
  const [loader, setLoader] = useState(true);
  const [questList, setQuestList] = useState([]);
  const [openModal, setOpenModal] = useState(false)
  const [programmingEvaluetionIds, setProgrammingEvaluetionIds] = useState({})
  const [timer, setTimer] = useState('00:00:00');
  const [userOutput, setUserOutput] = useState("");
  const [userCode, setUserCode] = useState('');
  // this line disable code
  useRestrictCopyPaste({ window, actions: ["copy", "cut", "paste"] });

  const [, setActiveTimeOut] = useState(false)

  const Ref = useRef(null);
  const exam_submit = useRef(null);

  const getSelectedAnswerOption = (item, question, selectedOption) => {
    let question_type = item.question_type;
    let question_type_name = item.question_type_key;
    let updateObj = candidateGivenAnswers.exam_data.answers[question_type_name];
    let filterQuestion = updateObj.filter(
      (x) => x.question === question && x.selectedOption === selectedOption
    );
    if (filterQuestion.length > 0) {
      updateObj.splice(updateObj.indexOf(filterQuestion[0]), 1);
    } else {
      updateObj.push({ question, selectedOption, question_type });
    }
    updateObj &&
      updateObj.length > 0 &&
      setCandidateGivenAnswers({
        ...candidateGivenAnswers,
        [question_type_name]: updateObj,
      });
    };


  const disableEnspectRightClick = () => {
    // Disable right-click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
      // Disable F12,F122, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 17 ||
        e.keyCode === 27 ||
        e.keyCode === 116 ||
        e.keyCode === 122 ||
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        ctrlShiftKey(e, "R") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      )
        return false;
    };
  };

  useEffect(() => {
    setInterval(() => {
      setExamTimeRemaining(() => examDuration * 60 - 1);
    }, 1000);
    disableEnspectRightClick();
    const element = document.getElementById("target");
    element.style.background = "white";
    screenfull.request(element);
    dispatch(getExamQuestions(candidateSelectedExam.state.data.id));
  }, []);


  if (candidateSelectedExamQuestions.data && stepLoader === true) {
    if (candidateSelectedExamQuestions.data.schedule_status === 3) {
      navigate("/my-test");
      return false
    }
    let qc = 1;
    candidateSelectedExamQuestions.data.question_data.map((question) => {
      let qo = 65;

      steps.push({
        label: (
          <div>
            {
              <div>
                {" "}
                <strong>Question {qc++} : </strong> {question.title}
              </div>
            }
          </div>
        ),
        description: (
          <ul>
            {question.answer_option.map((item) => (
              <li key={item.id}>
                <div>
                  <input
                    onClick={() =>
                      getSelectedAnswerOption(question, question.id, item.id)
                    }
                    type="checkbox"
                    name="site_name"
                    className="radio"
                    value={"ss"}
                  />{" "}
                  {String.fromCharCode(qo++)}. {item.answer_option}
                </div>
              </li>
            ))}
          </ul>
        ),
      });
    });
    //setSteps(steps)
    setMaxSteps(steps.length);
    setStepLoader(false);
  }


  //current date and time
  setInterval(() => {
    setCurrentDateTime((currentDateTime) =>
      moment().format("YYYY-MM-DD h:mm A")
    );
  }, 60000);


  const examTimer = () => {return };

  const startExam = () => {
    setIsExamStarted(true);
    setExamStartDateTime(moment().format("YYYY-MM-DD h:mm A"));
    examTimer();
    dispatch(getStatusScheduleExam({ exam_schedule_id: candidateSelectedExam.state.data.id, schedule_status: 2 }))
    onClickReset()
  };

  const examDurationOver = () => {
    navigate("/dashboard");
  };

  const submitExam = () => {
    const element = document.getElementById("target");
    element.style.background = "white";

    let array = [];
    let array2 = [];
    let total_marks = 0; questList.map((x) => total_marks += x.marks);
    Object.keys(myAnswer).forEach(function (key, index) {
      let filterData = questList.filter((x) => x.id == key);
      if (filterData.length > 0 && filterData[0].question_type_key === "mcq") {
        let data = {
          [key]: myAnswer[key]
          // marks: filterData[0].marks,
          // question_type: filterData[0].question_type,
          // question: key,
          // selectedOption: myAnswer[key],
        };
        array.push(data);
      }
      if (filterData.length > 0 && filterData[0].question_type_key === "programming") {
        let data = {
          [key]: myAnswer[key]
          //marks: filterData[0].marks,
          // question_type: filterData[0].question_type,
          // programing_question_evalution: key,
          // code: myAnswer[key],
        };
        array2.push(data);
      }
    });
    let answer = {
      "candidate": candidateSelectedExamQuestions.data.candidate,
      "candidate_exam_schedule": candidateSelectedExamQuestions.data.id,
      "exam_start_datetime": new Date(examStartDateTime),
      "exam_end_datetime": new Date(),
      "total_marks": total_marks,
      "total_answered_questions": Object.keys(myAnswer).length,
      "total_unanswered_question": totalQuestions - Object.keys(myAnswer).length,
      //"total_time_taken": 40,
      'all_question_data':questList,
      "candidate_question_answer_data": {
        "mcq": array,
        "programming": array2
      },
      "status": true,
      "created_by": GetLocalstorage.userDetail().id
    }
    let txt = "You pressed OK!";
    let status = false
    if (timer == '00:00:00') {
      status = true
    }else{
      if (window.confirm("Press a button!")) {
        txt = "You pressed OK!";
        status = true
      } else {
        txt = "You pressed Cancel!";
        screenfull.request(element);
        status = false
      }
    }

    if (status === true) {
      setLoader('submit')
      clearInterval(Ref.current)
      dispatch(completeCandidateExamPost(answer))
    }
    setCandidateGivenAnswers(answer)

  };

  if (loader === 'submit' && status === `${STATUSES.SUCCESS}_examResultData` && examResultData && examResultData.data) {
    setLoader('final_destination')
    dispatch(getStatusScheduleExam({ exam_schedule_id: candidateSelectedExam.state.data.id, schedule_status: 3 }))
  }

  if (loader === 'submit' && status === `${STATUSES.FAILURE}` && error) {
    setLoader(false)
    alert(error.message)
  }



  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total, hours, minutes, seconds
    };
  }

  const clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next    
    //setTimer('00:00:00');

    // If you try to remove this line the 
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      let { total, hours, minutes, seconds }
        = getTimeRemaining(e);
      if (total >= 0) {

        // update the timer
        // check if less than 10 then we need to 
        // add '0' at the beginning of the variable
        setTimer(
          (hours > 9 ? hours : '0' + hours) + ':' +
          (minutes > 9 ? minutes : '0' + minutes) + ':'
          + (seconds > 9 ? seconds : '0' + seconds)
        )
      } else {
        setActiveTimeOut(true)
        clearInterval(Ref.current)
        exam_submit.current.click()
      }
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    //examDuration
    deadline.setMinutes(deadline.getMinutes() + examDuration);
    return deadline;
  }
  const onClickReset = () => {
    clearTimer(getDeadTime());
  }


  const handleClickOpen = () => {
    setOpenModal(false)
    const element = document.getElementById("target");
    element.style.background = "white";
    screenfull.toggle(element);
    setFinish(true);
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  if (
    loader === true &&
    candidateSelectedExamQuestions &&
    candidateSelectedExamQuestions.data
  ) {

    setLoader(false);
    setQuestList(candidateSelectedExamQuestions.data.question_data);
  }

  const checkAnswer = (quest, variant) => {
    setMyAnswer({ ...myAnswer, [quest.id]: variant.id });
    setClickAnswer(true);
  };

  const updateEvaluationId = (variant, quest, userCode, userOutput) => {

    if (myAnswer[quest] === quest) {
      ///nothing
    } else {
      setMyAnswer({ ...myAnswer, [quest]: variant });
    }
    console.log("inside updateEvaluationId");
    setProgrammingEvaluetionIds({ ...programmingEvaluetionIds, [quest]: { userCode, userOutput, variant } })
  }

  const checkCorrectAnswer = () => {
    if (
      myAnswer === candidateSelectedExamQuestions.data &&
      candidateSelectedExamQuestions.data.question_data[currentQuestion].answer
    ) {
      setScore(score + 1);
    }
  };



  const nextPrevious = () => {

    let questionId = questList[currentQuestion] && questList[currentQuestion].id
    let output = ''
    let userCodeIs = myAnswer && myAnswer[currentQuestion] && myAnswer[currentQuestion].userCode ? myAnswer[currentQuestion].userCode : '';
    updateEvaluationId(0, questionId, userCodeIs, output)
    setUserOutput('');
  }
  const reset = () => {
    setShow(false);
    setClickAnswer(false);
    let user_code = myAnswer && myAnswer[currentQuestion] && myAnswer[currentQuestion].userCode ? myAnswer[currentQuestion].userCode : '';
    let user_output = myAnswer && myAnswer[currentQuestion] && myAnswer[currentQuestion].userCode ? myAnswer[currentQuestion].userOutput : '';


    if(questList[currentQuestion] && questList[currentQuestion].question_type_key === 'programming'){
      console.log("nextPrevious");
      nextPrevious()
    }
    setUserCode("")
    setUserOutput(user_output)
  };

  const finishHandler = () => {
    setFinish(true);

    if (
      currentQuestion === candidateSelectedExamQuestions.data &&
      candidateSelectedExamQuestions.data.question_data.length - 1
    ) {
      setFinish(true);
    }
    setTimeout(() => {
      examDurationOver()
    }, 5000);
  };

  if (loader === 'final_destination' && status === `${STATUSES.SUCCESS}_statusScheduleExam` && statusScheduleExam && statusScheduleExam.status) {
    setLoader(false)
    finishHandler()
  }

const startOver = () => {
    setCurrentQuestion(0);
    setFinish(false);
    setMyAnswer("");
    setScore(0);
  };



  let next = currentQuestion < questList.length - 1;
  let previous = true;

  return (
    <>
      <div className="new">
        {/* <Sidebar /> */}
        <div className="newContainer">
          {/* <Navbar /> */}
          {finish ? (
            <div
              className="thanksMsg"
              style={{ marginTop: "80px", textAlign: "center" }}
            >
              <h2>
                Your test is finished. We will connect with you asap. Thanks!
              </h2>
              <p>Plaese wait... It will redirect on dashboard in 5 seconds</p>
            </div>
          ) : (
            <div id="target">
              <div className="row top-headings">
                <h3>{candidateSelectedExam.state.data.technology.name} Test</h3>
              </div>
              <div className="termand-condition-data" style={{ position: "relative" }}>
                <div className="row head-line">
                  <div className="col">
                    <strong>Current Date & Time : </strong> {currentDateTime}
                  </div>
                  <div className="col">
                    <strong>
                      {examStartDateTime != "" ? "Test Start Time :" : ""}{" "}
                    </strong>
                    {examStartDateTime}
                  </div>
                  <div className="col">
                    <strong>Duration : </strong>
                    {examDuration} Minutes
                  </div>
                </div>
                <div className="row head-line">
                  <div className="col">
                    <strong>
                      {examStartDateTime != "" ? "Answered :" : ""}
                    </strong>{" "}
                    {Object.keys(myAnswer).length > 0
                      ? Object.keys(myAnswer).length + " of " + totalQuestions
                      : ''}
                  </div>

                  {/* <div className="col"><strong>{(examStartDateTime != '') ? 'Answered :' : ''}</strong> {(examStartDateTime != '') ? totalAnsweredQuestions + ' of ' + totalQuestions : ''}</div> */}
                  <div className="col"></div>
                  <div className="col">
                    <strong>
                      {examStartDateTime != "" ? "Time Remaining :" : ""}
                    </strong>
                    {examStartDateTime != "" ? (
                      <h2>{timer}</h2>
                      // <Countdown
                      //   onComplete={() => examDurationOver()}
                      //   precision={3}
                      //   date={Date.now() + examDuration * 60 * 1000}
                      // />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row head-line">
                  <div className="col"></div>
                  <div className="col"></div>
                  <div className="col">
                    {!iseExamStarted ? (
                      <button
                        className="btn btn-primary btn-md"
                        onClick={() => startExam()}
                      >
                        Start Test
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-md"
                        onClick={() => submitExam()}
                        ref={exam_submit}
                      >
                        Submit Test
                      </button>
                    )}
                  </div>
                </div>
                <div className="row head-line">
                  <hr />
                  {
                    iseExamStarted && (
                      <div style={{ overflow:"scroll",height:"700px" }} className="container m-4 p-4 mx-auto h-min-screen grid grid-rows-1 grid-cols-1 items-center">
                        <div className="wrapper">
                          <p className="question-wrapper">
                            <strong>Question {currentQuestion + 1} : </strong>{" "}
                            {candidateSelectedExamQuestions.data && <CopyBlock
                              language="java"
                              text={candidateSelectedExamQuestions.data.question_data[
                                currentQuestion
                              ].title}
                              codeBlock
                              theme={Snippet}
                              showLineNumbers={false}
                            />}

                          </p>
                          <p>
                            <strong>Answer : </strong>
                          </p>

                          {candidateSelectedExamQuestions.data &&
                            candidateSelectedExamQuestions.data.question_data[
                              currentQuestion
                            ].question_type_key === "programming" ? (
                            <div className="m-2 h-14 border-2 border-black mx-auto">
                              <UcCodeEditor
                                skill={
                                  candidateSelectedExam.state.data.technology
                                    .technology_key
                                }
                                candidateExamData={candidateSelectedExam.state.data}
                                questionId={questList[currentQuestion] && questList[currentQuestion].id}
                                setProgrammingIds={updateEvaluationId}
                                myAnswer={myAnswer}
                                programmingEvaluetionId={programmingEvaluetionIds}
                                setUserOutput={setUserOutput}
                                userOutput={userOutput}
                                userCode={userCode}
                                setUserCode={setUserCode}

                              />
                            </div>
                          ) : (
                            candidateSelectedExamQuestions.data &&
                            candidateSelectedExamQuestions.data.question_data[
                              currentQuestion
                            ].answer_option.map((variant, i) => (
                              <div key={i} className="m-2 h-14 border-2 border-black mx-auto">
                                <p style={{ paddingLeft: "70px" }}>
                                  <input
                                    onClick={() =>
                                      checkAnswer(
                                        questList[currentQuestion],
                                        variant
                                      )
                                    }
                                    type="radio"
                                    name="site_name"
                                    className="radio"
                                    checked={
                                      myAnswer[
                                      questList[currentQuestion] &&
                                      questList[currentQuestion].id
                                      ] === variant.id
                                    }
                                    value={"ss"}
                                  />
                                  {" " + variant.answer_option}
                                </p>
                              </div>
                            ))
                          )}
                          {/* {currentQuestion < candidateSelectedExamQuestions.data && candidateSelectedExamQuestions.data.question_data && candidateSelectedExamQuestions.data.question_data.length && ( */}
                          <div className="btns" >
                            {previous && currentQuestion !== 0 && (
                              <button
                                style={{ marginTop: '10px' }}
                                className="btn btn-primary btn-md"
                                onClick={() => {
                                  setCurrentQuestion(currentQuestion - 1);
                                  checkCorrectAnswer();
                                  reset();
                                }}
                              >
                                PREVIOUS
                              </button>
                            )}

                            {/* {currentQuestion < candidateSelectedExamQuestions.data && candidateSelectedExamQuestions.data.question_data && candidateSelectedExamQuestions.data.question_data.length && ( */}
                            {next && (
                              <button
                                style={{ marginLeft: "10px", marginTop: '10px' }}
                                className="btn btn-primary btn-md"
                                onClick={() => {
                                  setCurrentQuestion(currentQuestion + 1);
                                  checkCorrectAnswer();
                                  reset();
                                }}
                              >
                                NEXT
                              </button>
                            )}

                            {currentQuestion ===
                              candidateSelectedExamQuestions.data &&
                              candidateSelectedExamQuestions.data
                                .question_data &&
                              candidateSelectedExamQuestions.data.question_data
                                .length && (
                                <button
                                  className="btn btn-primary btn-md"
                                  onClick={() => finishHandler()}
                                >
                                  FINISH
                                </button>
                              )}
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          )}

          {/* for delete popup */}
          <DeleteDialogModal
            open={openModal}
            onClose={handleClose}
            heading={'Confirmation'}
            paragraph={'Are sure want to submit'}
            handleArchive={handleClickOpen}
            id={candidateGivenAnswers}
          />
        </div>
      </div>
    </>
  );
}
