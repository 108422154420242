import React from 'react'
import HorizontalLinearStepper1 from './Stepformnew';

const Testform= () => {
    return (
        <>
            <HorizontalLinearStepper1/>
        </>
    );
};
export default Testform;

