import { createSlice } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";

const uiSlice = createSlice({
    name: "navtoggle",
    initialState: {
        data: [],
        status: STATUSES.SUCCESS,
        currentMenu:"",
    },
    reducers: {
        // add your non-async reducers here
        navbartoggle: (state, action) => {
          state.data = action.payload
        },
      },
  
});

export const {navbartoggle, changeCurrentMenu} = uiSlice.actions


export default uiSlice.reducer;