import React, { useEffect, useState } from 'react'
import "./login.scss";
import Logo from "../../images/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login, loginWithOtp, userlogin } from "../../redux/features/authSlice";
import { toast, ToastContainer } from "react-toastify";
import LoaderForm from '../../components/common-function/LoaderForm';
import { STATUSES } from '../../redux/common-status/constant';
import { getAsigneRoleMenuList } from '../../redux/features/masterSlice';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import axios from 'axios';
import { InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Tab, Tabs } from "@mui/material"

const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role_id
const roleWiseFilter = tmsRole && `{"filter":{"status":{"value":"True","operation": "eq"},"role_id":{"value":${tmsRole},"operation": "eq"}},"no_limit":True}`
const { REACT_APP_API_BASEURL } = process.env;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

export default function Login() {
  const { REACT_APP_ENV } = process.env;

  const dispatch = useDispatch();
  const { authData, status, token, authLoggedIn, error, loginError, loginWithOTP } = useSelector((state) => state.authUser)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formErrors, setFormErrors] = useState('');
  const [loader, setLoader] = useState(false)

  //otp loging related states
  const [tabvalue, settabValue] = useState(0);
  const [otp, setOtp] = useState('');
  const [showOtpSection, setShowOtpSection] = useState(false)
  const [showNextBtn, setShowNextBtn] = useState(false)
  const [errStatus, setErrStatus] = useState(false);
  const [validateErr, setValidateErr] = useState("")
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const validate = (email, contact) => {
    const errors = {}

    if (email !== null && contact === null) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'Please enter valid email address'
      } else {
        errors.email = true
      }
    }

    var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
    if (contact !== null && email === null) {
      if (!pattern.test(contact)) {
        errors.contact = "Please enter number only";
      } else if (contact.length !== 10) {
        errors.contact = "Please enter valid mobile number";
      } else {
        errors.contact = true
      }
    }
    return errors
  }

  const HandleLogin = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password
    }

    if (!email) {
      setFormErrors({ 'emailErr': ' Email is required' })

    } else
      if (!password) {
        setFormErrors({ 'passwordErr': 'Password is required' })

      } else
        if (!email && !password) {
          setFormErrors({ 'Err': 'Email and password is required' })

        } else
          if (email && password) {
            setFormErrors('');
            const isEmail = validate(email, null)
            if (isEmail.email === true) {
              setLoader(true)
              dispatch(login(data, navigate));
            }
            else {
              setFormErrors({ 'emailErr': 'Please enter valid email address' })
            }
          }
  }

  if (loader === true && token && token.token) {
    setLoader(false)
    dispatch(userlogin(token.token));
  }

  if (authLoggedIn === true && authData) {
    dispatch(getAsigneRoleMenuList(roleWiseFilter))
    if (authData.data && authData.data.tms_role && authData.data.tms_role.role_key === 'candidate') {
      let url = REACT_APP_API_BASEURL + `evaluation/candidate-registration/${authData.data.id}`
      axios({
        'method': 'GET',
        'url': url,
        "headers": {
          "Content-type": "application/json",
          "Authorization": token.token,
        }
      }).then(
        (res) => {
          let response = res.data
          if (response.data && response.data.candidate_technologies && response.data.candidate_technologies.length > 0) {
            navigate("/dashboard");
            window.location.reload();
          } else {
            navigate("/edit-profile", { state: authData.data });
            window.location.reload();
          }
        }
      ).catch((error) => { })
      // navigate("/edit-profile", {state:authData.data});
      // window.location.reload();


    } else {
      navigate("/dashboard");
      window.location.reload();
    }

  }

  if (status === STATUSES.FAILURE && authLoggedIn === false && error && error.message !== "Success" && loader === true) {
    toast.error(error.message)
    setLoader(false)
  }

  if (status === STATUSES.FAILURE && loginError && loginError.message && loginError.message !== "Success" && loader === true && error === "") {
    toast.error(loginError.message)
    setLoader(false)
  }
  useEffect(() => {
    if (localStorage.getItem(`${REACT_APP_ENV}_CES`)) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleTabChange = (event, newValue) => {
    settabValue(newValue);
    setFormErrors("")
    setEmail("")
    setPassword("")
    setOtp("")
    setShowNextBtn(false)
  };

  //send otp section
  const sendOtp = (e) => {
    e.preventDefault();
    if (email === "") {
      setErrStatus(true)
      setFormErrors({ 'emailErr': 'Email or mobile number is required' })
      setValidateErr("")
    }
    if (email !== "") {
      const checkEmail = email.includes('@')
      let data;
      let validateCheck
      if (checkEmail === true) {
        validateCheck = validate(email, null)
        data = { email: email, }
      } else {
        validateCheck = validate(null, email)
        data = { contact_no: email }
      }
      if (validateCheck.email === true || validateCheck.contact === true) {
        setErrStatus(false)
        setValidateErr("")
        setLoader(true)
        dispatch(loginWithOtp(data));
      } else {
        setErrStatus(true)
        setValidateErr(validateCheck)
      }
    }
  }

  //send otp response
  if (loader === true && status === `${STATUSES.SUCCESS}_loginWithOTP` && loginWithOTP && loginWithOTP.message) {
    if (loginWithOTP.status === 200) {
      setShowOtpSection(true)
      setShowNextBtn(true)
      toast.success(loginWithOTP.message);
      setTimeout(() => {
        navigate('/')
      }, 3000)
    } else {
      toast.error(loginWithOTP.message)
    }
    setLoader(false)
  }

  //verify otp section
  const submitVerifyOTP = (e) => {
    e.preventDefault();
    if (otp === "") {
      setFormErrors({ 'otpErr': 'OTP is required' })
    }
    if (otp !== "") {
      const data = {
        email: email,
        otp: otp,
      }
      setLoader(true)
      dispatch(login(data));
    }
  }

  return (
    <div className="signin">
      <div className="sign-in-row">
        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>
        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4>Sign in</h4>
          <ToastContainer />
          <Tabs
            value={tabvalue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab value={0} label="Login" />
            <Tab value={1} label="OTP Login" />

          </Tabs>
          <br />
          {/* normal login */}
          <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
            <form onSubmit={HandleLogin}>
              <div className="formInput">
                <label >Email Address </label>
                <input
                  name="email"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {formErrors && formErrors.emailErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.emailErr}</p>}
              </div>

              <div className="formInput">
                <label >Password </label>
                <div style={{position: 'relative'}}>
                <input
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputAdornment position="end" style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                </div>
                {formErrors && formErrors.passwordErr && <p style={{ color: "#96332c", fontSize: 13 }}>{formErrors.passwordErr}</p>}
                {formErrors && formErrors.Err && <p style={{ color: "#96332c", fontSize: 13 }} >{formErrors.Err}</p>}
              </div>

              <p className="text-right"><Link to={"/forgot-password"} className="forgot-password-link">Forgot Password</Link></p>
              <button type="submit"
                style={{ width: "100%" }}
                className="btn btn-submit">
                Login</button>
              {/* link for candidate registration */}
              <p> Don't have an account ?
                <Link to={"/public/candidate-registration"} className="forgot-password-link" style={{ display: "inline-block", marginTop: "15px" }}> Sign up</Link>
              </p>
              {loader && <LoaderForm />}
            </form>
          </TabPanel>

          {/* login with otp*/}
          <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
            <OtpLoginComponent
              email={email}
              setEmail={setEmail}
              formErrors={formErrors}
              errStatus={errStatus}
              validateErr={validateErr}
              sendOtp={sendOtp}
              setShowOtpSection={setShowOtpSection}
              showOtpSection={showOtpSection}
              submitVerifyOTP={submitVerifyOTP}
              otp={otp}
              setOtp={setOtp}
              loader={loader}
              setShowNextBtn={setShowNextBtn}
              showNextBtn={showNextBtn}
            />
          </TabPanel>

        </div>
      </div>
    </div>
  )
}

//opt login component 
export const OtpLoginComponent = ({ email, setEmail, formErrors, sendOtp, loader, validateErr, errStatus,
  showOtpSection, setShowOtpSection, setOtp, otp, submitVerifyOTP, showNextBtn, setShowNextBtn }) => {
  return <>
    <div>
      {loader === true && <LoaderForm />}
      {
        showOtpSection === false ?
          // send otp section
          <div>
            <form>
              <div className="formInput">
                <label>Email or mobile number </label>
                <input
                  name="email"
                  type="email"
                  placeholder="Email or mobile number"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errStatus === true && validateErr === "" && formErrors && formErrors.emailErr && email === "" && <p className='error-message'>{formErrors.emailErr}</p>}
                {errStatus === true && validateErr && validateErr.email !== true && <p className='error-message'>{validateErr && validateErr.email}</p>}
                {errStatus === true && validateErr && validateErr.contact !== true && <p className='error-message'>{validateErr && validateErr.contact}</p>}

              </div>
              <div style={{ display: "flex" }}>
                <button type="submit"
                  onClick={sendOtp}
                  style={{ width: "100%" }}
                  disabled={loader === true ? true : false}
                  className={loader === true ? "submit-btn-disabled" : "btn btn-submit"}
                >Send OTP</button>
                {/* {
                  showNextBtn === true &&
                  <button type="submit"
                    style={{ backgroundColor: "#0052cc" }}
                    className="verify-button-section"
                    onClick={() => setShowOtpSection(true)}
                  >
                    Next</button>
                } */}
              </div>

            </form>
          </div>
          :
          // verify otp section
          <>
            <form>
              <div className="formInput">
                <span className='badge-section'>A One Time Passcode has been sent to <b>{email}</b></span>
              </div>

              <div className="formInput">
                <label> OTP </label>
                <input
                  name="otp"
                  type="text"
                  placeholder="OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {formErrors && formErrors.otpErr && otp === "" && <p className='error-message'>{formErrors.otpErr}</p>}

              </div>
              <div>
                <button type="submit"
                  style={{ width: "100%" }}
                  onClick={submitVerifyOTP}
                  disabled={loader === true ? true : false}
                  className={loader === true ? "submit-btn-disabled" : "btn btn-submit"}
                >
                  Verify OTP
                </button>
                <br />
                {/* link section */}
                <p className="text-right" style={{ marginLeft: "auto", marginBottom: "unset" }}>
                  <span className="forgot-password-link" onClick={sendOtp} style={{ lineHeight: "6px" }}>Resend OTP</span>
                  <span className="forgot-password-link" onClick={() => setShowOtpSection(false)}>Cancel</span>
                </p>

              </div>
            </form>

          </>
      }
    </div>
  </>
}
