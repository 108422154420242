import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const roleListOption = createAsyncThunk("get/role_dropDown", async () => {
    const res = await DataService.all_role_list();
    return res.data;
})

export const resourceListOption = createAsyncThunk(
    "get/resources_dropDown",
    async () => {
        const res = await DataService.get_all_resource_list();
        const resp = res.data;
        return resp;
    }
);

export const candidateListOption = createAsyncThunk(
    "get/candidate_dropDown",
    async () => {
        const res = await DataService.get_all_candidate_list();
        const resp = res.data;
        return resp;
    }
);

export const technologyListOption = createAsyncThunk(
    "get/technology_dropdown",
    async (params) => {
        const res = await DataService.all_technology_list(params);
        const resp = res.data;
        return resp;
    }
);

export const questionLevelListOption = createAsyncThunk(
    "get/questionlevel_dropdown",
    async (params) => {
        const res = await DataService.all_questionlevel_list(params);
        const resp = res.data;
        return resp;
    }
);

export const questionTypeListOption = createAsyncThunk(
    "get/questiontype_dropDown",
    async () => {
        const res = await DataService.all_QuestionType_list();
        const resp = res.data;
        return resp;
    }
);

export const setListOption = createAsyncThunk(
    "get/set_dropDown",
    async () => {
        const res = await DataService.all_questionSet_list();
        const resp = res.data;
        return resp;
    }
);

export const scheduleListOption = createAsyncThunk(
    "get/schedule_dropDown",
    async (params) => {
        const res = await DataService.all_schedule_list_by_candidate(params);
        const resp = res.data;
        return resp;
    }
);

const optionsSlice = createSlice({
    name: "option",
    initialState: {

        // DEMOS: [],
        // DEMOS_count:0,
        // DEMOS_status: STATUSES.IDLE,
        // DEMOS_error: '',

        resources: [],
        resourcesName: [],
        resourcesEmail: [],
        resources_count: 0,
        resources_status: STATUSES.IDLE,
        resources_error: '',

        roles_option: [],
        roles_candidate: [],
        roles_count: 0,
        roles_status: STATUSES.IDLE,
        roles_error: '',

        candidates: [],
        candidateName: [],
        candidateEmail: [],
        candidate_count: 0,
        candidate_status: STATUSES.IDLE,
        candidate_error: '',

        technology: [],
        technologys: [],
        technology_count: 0,
        technology_status: STATUSES.IDLE,
        technology_error: '',

        questionlevel: [],
        questionlevels: [],
        questionlevel_count: 0,
        questionlevel_status: STATUSES.IDLE,
        questionlevel_error: '',

        questiontype: [],
        questiontypes: [],
        questiontype_count: 0,
        questiontype_status: STATUSES.IDLE,
        questiontype_error: '',

        sets: [],
        sets_count: 0,
        sets_status: STATUSES.IDLE,
        sets_error: '',

        schedules: [],
        schedules_count: 0,
        schedules_status: STATUSES.IDLE,
        schedules_error: '',
    },
    reducers: {},
    extraReducers(builder) {
        builder
            // GET resource LISTOption
            .addCase(resourceListOption.pending, (state, action) => {
                state.resources_status = STATUSES.LOADING;
            })
            .addCase(resourceListOption.fulfilled, (state, action) => {
                state.resources_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.resources_error = !!action.payload.data;
                state.resources = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: `${e.user.name} (${e.email})`, value: e.id }));
                state.resourcesName = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.user.name, value: e.id }));
                state.resourcesEmail = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.email, value: e.email }));
                state.resources_count = action.payload.data.count;
            })
            .addCase(resourceListOption.rejected, (state, action) => {
                state.resources_status = STATUSES.FAILURE;
                state.resources_error = action.error
            })

            // GET candidate LISTOption
            .addCase(candidateListOption.pending, (state, action) => {
                state.candidate_status = STATUSES.LOADING;
            })
            .addCase(candidateListOption.fulfilled, (state, action) => {
                state.candidate_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.candidate_error = !!action.payload.data;
                state.candidates = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: `${e.user.name} (${e.email})`, value: e.id }));
                state.candidateName = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.user.name, value: e.id }));
                state.candidateEmail = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.email, value: e.email }));
                state.candidate_count = action.payload.data.count;
            })
            .addCase(candidateListOption.rejected, (state, action) => {
                state.candidate_status = STATUSES.FAILURE;
                state.candidate_error = action.error
            })


            // GET technology LISTOption
            .addCase(technologyListOption.pending, (state, action) => {
                state.technology_status = STATUSES.LOADING;
            })
            .addCase(technologyListOption.fulfilled, (state, action) => {
                state.technology_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.technology_error = !!action.payload.data;
                state.technology = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.technologys = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.name, value: e.id }));
                state.technology_count = action.payload.data.count;
            })
            .addCase(technologyListOption.rejected, (state, action) => {
                state.technology_status = STATUSES.FAILURE;
                state.technology_error = action.error
            })

            //  questionLevel LISTOption
            .addCase(questionLevelListOption.pending, (state, action) => {
                state.questionlevel_status = STATUSES.LOADING;
            })
            .addCase(questionLevelListOption.fulfilled, (state, action) => {
                state.questionlevel_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.questionlevel_error = !!action.payload.data;
                state.questionlevel = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.questionlevels = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.level_name, value: e.id }));
                state.questionlevel_count = action.payload.data.count;
            })
            .addCase(questionLevelListOption.rejected, (state, action) => {
                state.questionlevel_status = STATUSES.FAILURE;
                state.questionlevel_error = action.error
            })

            // GET question type LISTOption
            .addCase(questionTypeListOption.pending, (state, action) => {
                state.questiontype_status = STATUSES.LOADING;
            })
            .addCase(questionTypeListOption.fulfilled, (state, action) => {
                state.questiontype_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.questiontype_error = !!action.payload.data;
                state.questiontype = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.questiontypes = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.question_type, value: e.id }));
                state.questiontype_count = action.payload.data.count;
            })
            .addCase(questionTypeListOption.rejected, (state, action) => {
                state.questiontype_status = STATUSES.FAILURE;
                state.questiontype_error = action.error
            })


            // GET role LISTOption
            .addCase(roleListOption.pending, (state, action) => {
                state.roles_status = STATUSES.LOADING;
            })
            .addCase(roleListOption.fulfilled, (state, action) => {
                let array = []
                let array2 = []
                if (action.payload.status === 200) {
                    action.payload.data.rows.map((e) => e.role_key === 'candidate' && array2.push({ label: e.role_name, value: e.id, id: e.id }))
                    action.payload.data.rows.map((e) => e.role_key !== 'candidate' && array.push({ label: e.role_name, value: e.id, id: e.id }))
                    state.roles_status = STATUSES.SUCCESS;
                    state.roles_option = array
                    state.roles_candidate = array2
                    state.roles_count = action.payload.data.count;
                } else {
                    state.roles_status = STATUSES.FAILURE;
                    state.roles_error = !!action.payload.data;
                }
            })
            .addCase(roleListOption.rejected, (state, action) => {
                state.roles_status = STATUSES.FAILURE;
                state.roles_error = action.error
            })

            // GET resource LISTOption
            .addCase(setListOption.pending, (state, action) => {
                state.sets_status = STATUSES.LOADING;
            })
            .addCase(setListOption.fulfilled, (state, action) => {
                state.sets_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.sets_error = !!action.payload.data;
                state.sets = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.set_name, value: e.id }));
                state.sets_count = action.payload.data.count;
            })
            .addCase(setListOption.rejected, (state, action) => {
                state.resources_status = STATUSES.FAILURE;
                state.resources_error = action.error
            })

            // GET schedule LISTOption
            .addCase(scheduleListOption.pending, (state, action) => {
                state.sets_status = STATUSES.LOADING;
            })
            .addCase(scheduleListOption.fulfilled, (state, action) => {
                state.schedules_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.schedules_error = !!action.payload.data;
                state.schedules = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.schedule_name, value: e.id }));
                state.schedules_count = action.payload.data.count;
            })
            .addCase(scheduleListOption.rejected, (state, action) => {
                state.schedules_status = STATUSES.FAILURE;
                state.schedules_error = action.error
            })
    },
});


export default optionsSlice.reducer;