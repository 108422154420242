import React, { useEffect } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import Widget from "../../components/widget/Widget"
import "./dashboardMenu.scss"
import { useDispatch, useSelector } from "react-redux";
import { allResources } from "../../redux/features/dashboardSlice";
const { REACT_APP_ENV } = process.env;
const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))

const DashboardMenu = () => {

    if (window.location === window.location.origin + '/dashboard' || window.location === `${window.location.origin}/dashboard`) {
        window.location = '/dashboard?reload=done'
    }
    const dispatch = useDispatch();
    const { resourceList } = useSelector(state => state.dashboardCard)
    const tmsRole = rtm_user?.user && rtm_user.user.data && rtm_user.user.data.tms_role
    useEffect(() => {
        if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
            dispatch(allResources(`{"skip":0, "limit":10}`))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])


    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />

                <div className="widgets">
                    {
                        tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ?
                            <>
                                {/* <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                <Widget type="projects" count={0} />
                                <Widget type="shifts" count={0} />
                                <Widget type="reports" count={2} /> */}
                            </>
                            : tmsRole && (tmsRole.role_key === 'evaluator') ?
                                <Widget type="evaluation" count={(resourceList && resourceList.count) ? resourceList.count : ''} />
                                :
                                <Widget type="mytest" count={(resourceList && resourceList.count) ? resourceList.count : ''} />
                    }
                </div>
            </div >

        </div >
    )
}

export default DashboardMenu