import "./navbar.scss"
import React, { useState, useEffect } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsNoneOutlined from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { navbartoggle } from "../../redux/features/uislice";
import LocalStorageDataService from '../common-function/GetLocalstorage';
import moment from "moment";
import { logout } from '../../redux/features/authSlice'
import { STATUSES } from "../../redux/common-status/constant";
import dataService from "../../redux/services/data.service";
import { Logout } from "@mui/icons-material";

const timeOutInterval = 10000
const Navbar = (props) => {
  let timer ;
  //const events = ['click', 'scroll', 'load', 'keydown']
  const events = ['click']
  const eventHandler = (eventType) => {
    return;
  };
  const dispatchtheme = useDispatch()
  // const navigate = useNavigate()
  const { status } = useSelector((state) => state.authUser)
  const [toggle, settoggle] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [anchorEl1, setAnchorEl1] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [errMessage, setErrMessage] = useState("Session Expired!")
  const openmessage = Boolean(anchorEl1);

  const messageClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const messageClose = () => {
    setAnchorEl1(null);
  };




  const navbartoggles = () => {
    settoggle(toggle => !toggle);
    dispatchtheme(navbartoggle(toggle));

  }

  useEffect(() => {
    addEvents();

    return (() => {

      removeEvents();
    })
    // eslint-disable-next-line
  }, [])

  const ckeckUserAccessToken = async () => {
    let token = LocalStorageDataService.userToken()
    if (token) {
      await dataService.userLogin(token).then(response => {
        
      }).catch((error) => {
        if (error.response) {
          let err = error.response
          if (err.status === 500) {
            //toast.info(err.statusText)
            // setErrMessage(err.statusText)
            // setOpenModal(true)
          }
          if (err.status === 401) {
            err.data?.message && setErrMessage(err.data.message)
            setOpenModal(true)
          }
        }
      });
    }
  }

  const startTimer = () => {
    const timeOut = 3600 * 1000 * 8
    const lastInteractionTime = LocalStorageDataService.lastInteractionTime()
    const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
    timer = setTimeout(() => {
      if (diff._milliseconds < timeOut) {
        ckeckUserAccessToken()
      } else {
        ckeckUserAccessToken()
      }
    }, timeOutInterval)


  }

  const logout_user = () => {
    setOpenModal(false)
    dispatchtheme(logout());
    window.location.reload('/')
  }

  const addEvents = () => {

    events.forEach(eventName => {
      window.addEventListener(eventName, eventHandler)
    })

    startTimer();
  }

  const removeEvents = () => {
    events.forEach(eventName => {
      window.removeEventListener(eventName, eventHandler)
    })
  };

  if (status === STATUSES.LOADING) {
    //window.location.reload('/')
  }

  //const { dispatch } = useContext(DarkModeContext)


  return (
    <>
      <div className="navbar">
        <div className="wrapper">
          <div className="toggle-navigation">
            {props.activeCandidate === true ? <Tooltip title="logout"><Logout className="icon" onClick={() => logout_user()} /></Tooltip>
              :

              <FormatAlignJustifyIcon className="icon" onClick={navbartoggles} />
            }
          </div>

          {/* <div className="search">
          <input type="text" placeholder="Search...." />
          <SearchOutlinedIcon />
        </div> */}
          <div className="items">
            {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div> */}
            {/* <div className="item">
            <DarkModeOutlined className="icon" onClick={() => dispatch({ type: "TOGGLE" })} />
          </div> */}
            {/* <div className="item">
            <FullScreenExitOutlinedIcon className="icon" />
          </div> */}
            <div className="item">
              <NotificationsNoneOutlined className="icon"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'notification-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              />
              <div className="counter">0</div>

            </div>
            <Menu
              anchorEl={anchorEl}
              className="MuiMenu-list-dropdown"
              id="notification-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>


              <MenuItem>
                <Link to={"/notification"} className="see-all-notifiation">All Notification</Link>
              </MenuItem>


            </Menu>
            <div className="item">
              <ChatBubbleOutlinedIcon className="icon"
                onClick={messageClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              />
              <div className="counter">1</div>
            </div>
            <Menu
              anchorEl={anchorEl1}
              className="MuiMenu-list-dropdown"
              id="message-menu"
              open={openmessage}
              onClose={messageClose}
              onClick={messageClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>
              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <span className="notification-head">Post like by someone bored last friday</span>
                <span className="notification-date">26/09/2022 09:01 AM</span>
              </MenuItem>

              <MenuItem>
                <Link to={"/all-massages"} className="see-all-notifiation">All Messages</Link>
              </MenuItem>



            </Menu>
            {/* <div className="item">
            <ListOutLinedIcon className="icon" />
          </div> */}
            <div className="item">
              <span className="user-name-login">{LocalStorageDataService.userDetail() && LocalStorageDataService.userDetail().first_name}</span>
              {/* <img src="https://images.pexels.com/photos/1967902/pexels-photo-1967902.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" className="avatar" /> */}
            </div>
          </div>
          <Dialog
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">

            <DialogContent>
              <div className="popup-confirming">
                <h3>Alert</h3>
                <p>{errMessage}</p>
                <button className="yes" onClick={() => logout_user()}>LOG-OUT</button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>

    </>
  )
}

export default Navbar
