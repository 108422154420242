import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";


export const resourceList = createAsyncThunk("resource/list", async (filterData) => {
    const res = await DataService.resource_list(filterData);
    return res.data;
});
export const getCandidateList = createAsyncThunk("candidate/list", async (filterData) => {
    const res = await DataService.candidate_list(filterData);
    return res.data;
});


export const resourceViewById = createAsyncThunk("resource/view", async (id) => {
    const res = await DataService.resource_view(id);
    return res.data;
});
export const candidateViewById = createAsyncThunk("candidate/view", async (id) => {
    const res = await DataService.candidate_view(id);
    return res.data;
});

export const resourceAddNew = createAsyncThunk("resource/add", async (data) => {
    const res = await DataService.resource_add(data);
    return res.data;
}
);

export const resourceEdit = createAsyncThunk("resource/edit", async (id, data) => {
    const res = await DataService.resource_edit(id, data);
    return res.data;
}
);

export const resourceDeleteById = createAsyncThunk("resource/delete", async (id, data) => {
    const res = await DataService.resource_delete(id, data);
    return res.data;
});

export const candidatDeleteById = createAsyncThunk("candidate/delete", async (id, data) => {
    const res = await DataService.candidate_delete(id, data);
    return res.data;
});

export const tmsRoleList = createAsyncThunk("tmsrole/list", async () => {
    const res = await DataService.tms_role_list();
    return res.data;
}
);

export const resourceCreate = createAsyncThunk("resource/create", async (data) => {
    const res = await DataService.create_resources(data);
    return res.data;
});

export const candidateCreate = createAsyncThunk("candidate/create", async (data) => {
    const res = await DataService.create_candidate(data);
    return res.data;
});

export const resourceUpdateById = createAsyncThunk("resource/update", async (data) => {
    const res = await DataService.update_resources(data.id, data.addvalues);
    return res.data;
});

export const candidateUpdateById = createAsyncThunk("candidate/update", async (data) => {
    const res = await DataService.update_candidate(data.id, data.addvalues);
    return res.data;
});

export const allRoleList = createAsyncThunk("role-list/all", async () => {
    const res = await DataService.all_role_list();
    return res.data;
})

export const allResourcesListEdit = createAsyncThunk("resource-list/edit", async () => {
    const res = await DataService.resource_list_status();
    return res.data;
});

export const allResourcesList = createAsyncThunk("resource-list-by-manger/all", async () => {
    const res = await DataService.resource_list_status();
    return res.data;
});

export const downloadCandidateSample = createAsyncThunk("download-candidate-sample", async () => {
    const res = await DataService.download_samplefile_candidate();
    return res.data;
});

export const importCandidateFile = createAsyncThunk("import-candidate", async (id) => {
    const res = await DataService.import_file_candidate(id);
    return res.data;
});

export const candidateUpdateByIdFile = createAsyncThunk("candidate/update-file", async (data) => {
    const res = await DataService.update_candidate_file(data);
    return res.data;
});

const resourceSlice = createSlice({
    name: "resource_list",
    initialState: {
        data: [],
        view_data: [],
        empType: [],
        deptType: [],
        tmsRoles: [],
        createResource: [],
        updateResource: [],
        deleteResource: [],
        editResource: [],
        syncResource: [],
        resourceListForTsk: [],
        roleList: [],
        departmentList: [],
        empTypeList: [],
        status: STATUSES.IDLE,
        dataLoader: STATUSES.IDLE,
        error: '',
        activeResourceList: [],
        activeResourceFilter: [],
        allResourcesByManagerList: [],
        deleteCandidate: [],
        candidateList: [],
        updateCandidate: [],
        createCandidate: [],
        download_candidate_sample: [],
        import_candidate_file: [],

        upload_candidate_file: [],
        upload_candidate_status: STATUSES.IDLE,

        candidateDropDownList: [],
        candidateNameDropDownList: [],
        candidateEmailDropDownList: [],

    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(resourceList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                state.data = action.payload;
            })
            .addCase(resourceList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource View by id

            .addCase(resourceViewById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceViewById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.view_data = action.payload;
            })
            .addCase(resourceViewById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource add

            .addCase(resourceAddNew.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceAddNew.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.view_data = action.payload;
            })
            .addCase(resourceAddNew.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource Edit by id

            .addCase(resourceEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.editResource = action.payload;
            })
            .addCase(resourceEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///resource delete by id

            .addCase(resourceDeleteById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceDeleteById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_deleteResource`;
                state.deleteResource = action.payload;
            })
            .addCase(resourceDeleteById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///candidate delete by id

            .addCase(candidatDeleteById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(candidatDeleteById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_deleteCandidate`;
                state.deleteCandidate = action.payload;
            })
            .addCase(candidatDeleteById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///candidate list 
            .addCase(getCandidateList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
                state.error = ""
            })
            .addCase(getCandidateList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.dataLoader = STATUSES.SUCCESS;
                state.candidateList = action.payload;
                state.candidateDropDownList = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.first_name + `(${e.email})`, value: e.id }));
                state.candidateNameDropDownList = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.first_name, value: e.id }));
                state.candidateEmailDropDownList = !action.payload.data.rows ? [] : action.payload.data.rows.map((e) => ({ ...e, label: e.email, value: e.id }));
                state.error = ""
            })
            .addCase(getCandidateList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update candidate
            .addCase(candidateUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(candidateUpdateById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_updateCandidate`;
                state.updateCandidate = action.payload;
            })
            .addCase(candidateUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///candidate View by id

            .addCase(candidateViewById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(candidateViewById.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.view_data = action.payload;
            })
            .addCase(candidateViewById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create candidate

            .addCase(candidateCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(candidateCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createCandidate`;
                state.createCandidate = action.payload;
            })
            .addCase(candidateCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })



            ///get TMS Role list

            .addCase(tmsRoleList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(tmsRoleList.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.tmsRoles = action.payload;
            })
            .addCase(tmsRoleList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///create resource

            .addCase(resourceCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createResource`;
                state.createResource = action.payload;
            })
            .addCase(resourceCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///update resource

            .addCase(resourceUpdateById.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(resourceUpdateById.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_updateResource`;
                state.updateResource = action.payload;
            })
            .addCase(resourceUpdateById.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })


            ///All role list for filter
            .addCase(allRoleList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_roleList`;
                state.roleList = action.payload;
            })


            //All active resources for add/edit/view
            .addCase(allResourcesListEdit.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(allResourcesListEdit.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.activeResourceList = action.payload;
            })
            .addCase(allResourcesListEdit.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            ///All active resource list for filter
            .addCase(allResourcesList.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_resourceList`;
                state.activeResourceFilter = action.payload;
            })


            //download sample file Question
            .addCase(downloadCandidateSample.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(downloadCandidateSample.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_download_candidate_sample`;
                state.download_candidate_sample = action.payload;
            })
            .addCase(downloadCandidateSample.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //import file Questions
            .addCase(importCandidateFile.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(importCandidateFile.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_import_candidate_file`;
                state.import_candidate_file = action.payload;
            })
            .addCase(importCandidateFile.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //candidate file update
            .addCase(candidateUpdateByIdFile.pending, (state, action) => {
                state.upload_candidate_status = STATUSES.LOADING;
            })
            .addCase(candidateUpdateByIdFile.fulfilled, (state, action) => {
                state.upload_candidate_status = `${STATUSES.SUCCESS}_upload_candidate_file`;
                state.upload_candidate_file = action.payload;
            })
            .addCase(candidateUpdateByIdFile.rejected, (state, action) => {
                state.upload_candidate_status = STATUSES.FAILURE
                state.error = action.error
            })

    },
});


export default resourceSlice.reducer;