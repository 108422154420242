import React, { useState, useEffect } from 'react'
import "./resetpassword.scss";
import Logo from "../../images/logo.png"
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import dataService from '../../redux/services/data.service';
import LoaderForm from '../../components/common-function/LoaderForm';
const { REACT_APP_ENV } = process.env;

const ResetPassword = () => {

  const navigate = useNavigate('');
  const { resetToken } = useParams();

  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
    reset_token: resetToken,
  })

  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.new_password === "") {
      return toast.error("New Password is required")
    } else if (formData.confirm_password === "") {
      return toast.error("Confirm Password is required")
    }

    if (formData.new_password !== "" && formData.confirm_password !== "") {
      setLoading(true)
      const request = { "password": formData.new_password, "reset_token": resetToken }

      if (formData.new_password !== formData.confirm_password) {
        setLoading(false)
        return toast.error('Passwords must match')
      }
      const response = await dataService.resetPassword(request)
      if (response && response.data && response.data.status === 200) {
        toast.success(response.data.message)
        setLoading(false)
        setTimeout(() => {
          navigate('/')
        }, 2000)
      }
      if (response && response.data && response.data.status === 400) {
        toast.error(response.data.message)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const user = localStorage.getItem(`${REACT_APP_ENV}_CES`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_CES`))
    if (user && user.token) {
      navigate('/');
    }

    if (!resetToken) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (

    <div className="signin">

      <div className="sign-in-row">

        <div className="signin-col-1">
          <img src={Logo} alt={Logo} className="left-logo-icon" />
        </div>

        <div className="signin-col-2">
          <img src={Logo} alt={Logo} />
          <h4>Reset Password</h4>
          <ToastContainer />

          <form onSubmit={handleSubmit}>

            {loading && <LoaderForm />}
            <div className="formInput">
              <input
                type="hidden"
                placeholder="key"
                name="reset_token"
                defaultValue={resetToken}
              />
            </div>

            <div className="formInput">
              <label >
                New Password
              </label>
              <input
                type="password"
                placeholder="New Password"
                name="new_password"
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="formInput">
              <label >
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirm_password"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <button type="submit"
              style={{ width: "100%" }}
              className="btn btn-submit"
              disabled={loading} >Submit</button>
          </form>

        </div>

      </div>

    </div>
  )
}

export default ResetPassword
