import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./departments.scss"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDepartment,updateDepartment,} from '../../redux/features/departmentSlice';
import { DataGrid } from "@mui/x-data-grid";
import { departmentColumns } from "../../components/datatable-coulms/tableCoulmns";
import { TablePagination, Tooltip } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";

function Departments() {
  const dispatch = useDispatch();

  const { data, status, error , delete_Department ,update_Department} = useSelector(state => state.department)
  const { data: list } = data
  const [archiveId, setArchiveId] = useState(0)
  const [archiveOpen, setArchiveOpen] = useState(false)
  const [changeStatus, setChangeStatus] = useState('');
  const [reload, setReload] = useState(false)



  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    dispatch(getDepartment(filterQuery))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (reload === true && status === `${STATUSES.SUCCESS}_update_Department` && update_Department && update_Department.message) {
    if (update_Department.status === 200) {
        toast.success(update_Department.message)
        setArchiveOpen(false)

        dispatch(getDepartment(filterQuery))
    } else {
        toast.error(update_Department.message)
    }
    setReload(false)
}
  if (reload === true && status === `${STATUSES.SUCCESS }_delete_Department` && delete_Department && delete_Department.message) {
    if (delete_Department.status === 200) {
        toast.success(delete_Department.message)
        dispatch(getDepartment(filterQuery))
    } else {
        toast.error(delete_Department.message)
    }
    setReload(false)
}

  const handleArchive = (id) => {
    setArchiveId(id)
    setArchiveOpen(true)
    if (id.status === true) {
        setChangeStatus("InActive")
    } else {
        setChangeStatus("Active")
    }

}
  
  const handleClickArchive = (data) => {
      let addvalues = {
      name: data.name,
      code: data.code,
      description: data.description,
      status: !data.status,
      // updated_by: GetLocalstorage.userDetail().id
    }
    let ID = data.id
    setReload(true)
    dispatch(updateDepartment({ID,addvalues}))

  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent  deleteIconName="ArchiveIcon" moduleName="department" rowid={params.row.id} editLinkUrl={`/edit-department/${params.row.id}`} viewLinkUrl="" isDelete={true} editButton={false} viewButton={false} deleteButton={()=>handleArchive(params.row)}/>
          </div>
        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    dispatch(getDepartment(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getDepartment(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list?.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel) => {
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getDepartment(filterData))
    }
    if (filterModel.items && filterModel.items.length === 0) {
      setCurrentPage(0)
      // setPageSize(10)
      setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`)
      dispatch(getDepartment(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.DEPARTMENT_LIST_COLUMNS}]}`))
    }
  }

  return (

    <div className="master">
      <Sidebar />
      <div className="masterContainer">
        <Navbar />
        <div className="datatable">
          <div className="top-headings">
            <h3> Departments</h3>
            <div>
              <Tooltip title="Add New Department ">
                <Link to="/add-new-department" className="btn-add-new ">
                  <AddIcon />
                </Link>
              </Tooltip>
              {/* <RefreshButton api={getDepartment} filter={filterQuery} style={{ marginTop: 0 }} /> */}
            </div>

          </div>
          <ToastContainer />
          {(status === STATUSES.LOADING) && <LoaderForm />}
          <DataGrid
            className="datagrid"
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            rows={list?.rows ? list.rows : []}
            columns={departmentColumns.concat(actionColumn)}
            pageSize={pageSize}
            style={{ fontSize: 16 }}
            onFilterModelChange={onFilterChange}
            components={{
              Pagination: CustomPagination,
              // NoRowsOverlay: CustomNoRowsOverlay
            }}
          />
        </div>

        {/* for delete popup */}
        {/* <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={'Delete ?'}
          paragraph={'Are You Sure To Remove This Department'}
          handleArchive={handleClickOpen}
          id={deleteId}
          moduleName={'department'}
        /> */}
         <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Department`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
      </div>
    </div>
  )
}
export default Departments